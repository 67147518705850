import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useTranslation } from '../../../../../hooks/useTranslation';
import { helpers } from '../../../../helpers/helpers';


const MainCard = (props) => {
  
  const label = props.item ? useTranslation("texte", props.item.question) : "";
  const info = props.item ? useTranslation("info", props.item.question) : "";

  const onDragStart = (e, item) => {
    e.dataTransfer.setData("text/plain", item.id);
    let miniCard = document.createElement('div')
    miniCard.id = "mini-card-ghost"
    miniCard.style.top = "-100px"
    miniCard.style.left = "-100px"
    miniCard.innerHTML = label;
    document.getElementById('card-sorting').appendChild(miniCard);
    e.dataTransfer.setDragImage(miniCard,130,15)
  }
  
  const onDragEnd = (e)=>{
    document.getElementById('card-sorting').removeChild(document.getElementById('mini-card-ghost'))
  }
  
  var classNames = require('classnames');
  var mainCardClass = classNames({
    'hero': true,
    'flat narrow': !(props.item)
  });

  // Use this compoement to render html encoded accent
  const renderHTML = (rawHTML) => React.createElement("p", { dangerouslySetInnerHTML: { __html: rawHTML } });

  return(
    <div  id="main-card"
    className={mainCardClass}
    data-value={(props.item === undefined) ? '' : props.item.id}
    draggable={(props.item === undefined) ? false : true}
    onDragStart={(e)=>{onDragStart(e, props.item)}}
    onDragEnd={onDragEnd}
    >
      {props.item &&
      <div>
        <h2>
          {
            label
          }
        </h2>
        {
          info &&
          renderHTML(helpers.stripHtmlTags(info))
        }
      </div>
      }
      {!props.item &&
      <div>
        <h2>(<FormattedMessage id="app.global.label.empty" />)</h2>
      </div>
      }
    </div>
  )
}

  export default MainCard