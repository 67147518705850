import React from 'react';
import MainCard from './MainCard';
import DropZones from './DropZones';
import SortingButtons from './SortingButtons';
import { FormattedMessage } from 'react-intl';
import Consigne from './Consigne';


class PremierTri extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      currentQuestion: this.getNextQuestion(this.props.items),
      maxedOut: false,
      maxPerCol: undefined
    }
    
    this.saveHandler = this.saveHandler.bind(this);
    this.checkColMaxedOut = this.checkColMaxedOut.bind(this);
    this.reset2eTri = this.reset2eTri.bind(this);
  }
  
  componentDidMount(){
    this.checkColMaxedOut()
  }

  getNextQuestion(items){
    return items.find(item => item.id_reponse === null)
  }

  checkColMaxedOut(){
    let isMaxedOut = false
    let max = undefined

    for(let i = 0; i < this.props.paniers.length ; i++){
      max = this.props.paniers[i].nb_items
      let itemsPanier = this.props.items.filter( item => item.id_reponse === this.props.paniers[i].id).length
      if(itemsPanier > max){
        isMaxedOut = true
        break
      }
    }

    this.setState({maxedOut: isMaxedOut, maxPerCol: max})
  }

  reset2eTri(){
    Object.values(this.props.items2eTri).forEach(item => {
      if(item.id_reponses_multiples){
        this.props.saveHandler(item.id, {id_reponses_multiples: null}, () => {
          this.props.updateProgress();
        } );
      } 
    })
  }

  saveHandler(id, payload){
    this.props.saveHandler(id, payload, ()=> {
      this.props.updateProgress();
      this.setState({currentQuestion: this.getNextQuestion(this.props.items)});
      this.checkColMaxedOut();
      this.reset2eTri();
    });
  }
    
  render() {
    return(
      <div id="premier-tri">
        {this.state.currentQuestion &&
          <Consigne question={this.state.currentQuestion.question} />
        }
        <MainCard item={this.state.currentQuestion} lang={this.props.lang} />

        <SortingButtons
          options={this.props.paniers}
          currentItem={this.state.currentQuestion}
          disabled={!this.state.currentQuestion}
          click={this.saveHandler} />
        { this.state.maxedOut &&
          <p><i className="icon-warning-empty text-error" /> <FormattedMessage id="questionnaire.card-sorting.col-maxed-out" values={{max: this.state.maxPerCol}} defaultMessage="Le nombre maximum d'items par colonne a été dépassé."/></p>
        }
        <DropZones
          paniers={this.props.paniers}
          items={this.props.items}
          drop={this.saveHandler}
          removeCard={this.saveHandler} />
      </div>
      )
    }
  }

  export default PremierTri