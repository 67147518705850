import React from 'react';
import OptionItem from './OptionItem';

function OptionItems(props){
  const { current_question, onChange } = props;

  if(current_question.question.choix_de_reponses.length === 0){
    return null;
  }

  return(
    <div id="right-col">
      <div id="options-wrapper" className="options radio-group">
        {current_question.question.choix_de_reponses.map((option) => {
          return (
            <div key={option.id} className="form-item">
              <label htmlFor={'option-'+option.id} className={(current_question.id_reponse === option.id) ? 'selected' : null}>
                <input id={'option-'+option.id} type="radio" name="answer" value={option.id} checked={current_question.id_reponse === option.id} onChange={onChange} />
                <OptionItem option={option} />
                {(option.id_reponse_image) ? <img src={'/images/questions/'+option.id_reponse_image+'.png'} alt="" /> : ""}
              </label>
            </div>
          )
        })}
      </div>
      {props.children}
    </div>
  );
}

export default OptionItems;