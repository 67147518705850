import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import userTest from "./userTest.json";

const DemoAcademic10Home = (props) => {
  const intl = useIntl();

  return (
    <section id="demo-academic10-home">
      <div className="demo-pill"><FormattedMessage id="app.global.demo" /></div>
      <h1><FormattedMessage id="home.academic-10.title" /></h1>
      <p><FormattedMessage id="demo.academic10.p1" /></p>
      <p><FormattedMessage id="demo.academic10.p2" /></p>
      <p><FormattedMessage id="demo.academic10.p3" /></p>
      <p><FormattedMessage id="demo.academic10.p4" /></p>
      
      <div>
      <h3><FormattedMessage id="demo.academic10.p5" /></h3>
      <p><FormattedMessage id="demo.academic10.p6" /></p>
      <div className="table-wrapper">
        <table className="table-listing" style={{marginBottom: "60px"}}>
          <thead>
            <tr>
              <th><FormattedMessage id="app.global.label.name" /></th>
              <th><FormattedMessage id="app.global.label.approx_time" /></th>
              <th><FormattedMessage id="app.global.label.status" /></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{userTest?.test?.nom}</td>
              <td><span>1 min. </span></td>
              <td><FormattedMessage id="app.global.status.to-be-done" /></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
      <div className="text-center">
        <button className="btn btn-primary btn-lg" onClick={()=>props.next()}><FormattedMessage id="app.global.button.continue" /></button>
        <div style={{marginTop: "40px"}}>
            <a href={intl.formatMessage({ id: "demo.academic10.lang_url" })}>
              <img 
                src={intl.formatMessage({ id: "demo.academic10.lang_img_src" })}
                alt={intl.formatMessage({ id: "demo.academic10.lang_img_alt" })}
                style={{width: "427px"}}
             />
            </a>
          </div>
      </div>
    </section>
  );
};

export default DemoAcademic10Home;
