import React, {useState, useEffect} from 'react';
import MediumCard from './MediumCard'
import { useTranslation } from '../../../../../hooks/useTranslation';

const TopBottomPicker = (props) => {
    const [currentQuestion, setCurrentQuestion] = useState(props.currentQuestion);
    const [selectedItems, setSelectedItems] = useState([]);

    useEffect(() => {
      setCurrentQuestion(props.currentQuestion);
    }, [props.currentQuestion]);
    
    useEffect(() => {
      if(props.currentQuestion.id_reponses_multiples){
        setSelectedItems(props.currentQuestion.id_reponses_multiples.split(',').map(Number));
      }else{
        setSelectedItems([]);
      }
    }, [props.currentQuestion.id_reponses_multiples]);

    const onClickHandler = (id)=>{
      let clonedSelectedItems = selectedItems.slice() // Shallow copy!
      let clonedCurentQuestion = { ...currentQuestion }
      
      let index = selectedItems.indexOf(id)
      if(index !== -1){ // Item found, remove id from selected...
        clonedSelectedItems.splice(index,1)

        clonedCurentQuestion.id_reponses_multiples = clonedSelectedItems.join(',')
        props.saveHandler(currentQuestion.id, {id_reponses_multiples: clonedCurentQuestion.id_reponses_multiples}, 
          () => {
            props.updateProgress()
          })
        }
        else if(selectedItems.length < clonedCurentQuestion.question.type_reponse.nb_reponse_obligatoire){  // Add id to selected
          clonedSelectedItems.push(id)
          clonedCurentQuestion.id_reponses_multiples = clonedSelectedItems.join(',');
          props.saveHandler(currentQuestion.id, {id_reponses_multiples: clonedCurentQuestion.id_reponses_multiples}, 
            () => {
              props.updateProgress()
           })
      }
    }

    const isDisabled = (id)=>{
      if(selectedItems.indexOf(id) === -1 &&
         selectedItems.length >= currentQuestion.question.type_reponse.nb_reponse_obligatoire){
        return true
      }
      return false
    }

    const isChecked =(id) => {
      console.log('isChecked', id, selectedItems.indexOf(id));
      if(selectedItems.indexOf(id) === -1 ){
        return false
      }
      return true
    }

    const question = useTranslation("texte", currentQuestion.question);

      return(
        <div id="top-bottom-picker">
          <p>{ question }</p>
          <div id="medium-cards-wrapper">
        {
             props.items.map(
              (item, index)=>{
              return <MediumCard
                        key={index}
                        id_question={item.id_question}
                        question={item.question}
                        checked={isChecked(item.id_question)}
                        onClick={onClickHandler}
                        disabled={isDisabled(item.id_question)} />
                }
            )}
          </div>
        </div>
      );
  }
  export default TopBottomPicker;