import React from "react";
import Example from "../../intro/Example";
import Instructions from "../../intro/Instructions";
import { FormattedMessage } from "react-intl";

function InstructionsPopOver(props) {
  const { test, toggleInstructions, lang, isUserSessionMesuresAdaptees } = props;
  return (
    <div
      id='instructions-pop-over-wrapper'
      onClick={() => {
        toggleInstructions();
      }}>
      <div id='instructions-pop-over'>
        <div id='instructions-pop-over-body'>
          <Instructions test={test} lang={lang} isUserSessionMesuresAdaptees={isUserSessionMesuresAdaptees} />
          <Example test={test} />
        </div>
        <div id='instructions-pop-over-footer'>
          <button
            className='btn btn-default'
            onClick={() => {
              toggleInstructions();
            }}>
            <FormattedMessage id='app.global.button.close' />
          </button>
        </div>
      </div>
    </div>
  );
}

export default InstructionsPopOver;
