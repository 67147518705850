import * as actionTypes from "../actions/utilisateurTest/actionTypes";
import * as usq_actionTypes from "../actions/utilisateurQuestion/actionTypes";
import { combineReducers } from "redux";

export const utilisateurTest = (state = [], action) => {
  switch (action.type) {
    case actionTypes.FETCH_UTILISATEUR_TEST_SUCCESSFUL: {
      let temps_ecoule = action.response.temps_ecoule;
      // Make sure the server response is about the same test as the current state in localStorage (i.e. id from test in localStorage matches id of test from server response)
      if (state.id === action.response.id && state.hasOwnProperty('temps_ecoule')) {
        // Compare temps_ecoule from localStorage (hydrated from "state") and temps_ecoule from server response
        temps_ecoule = Math.max(state.temps_ecoule, action.response.temps_ecoule);
      }

        // Overridden elapsed time by admin?
        if (action.response.hasOwnProperty("overide_temps") && action.response.overide_temps !== null && Number(action.response.overide_temps) >= 0) {
          temps_ecoule = action.response.overide_temps;
        }

        return {
          ...state,
          ...action.response,
          temps_ecoule: temps_ecoule,
          test: action.response.test,
          questions: action.response.utilisateur_session_questions,
          paniers: action.response.paniers,
        };
    }
    case actionTypes.UPDATE_UTILISATEUR_TEST_ELAPSED_TIME: {
      if(state.id === action.id){

        return {
          ...state,
          temps_ecoule: action.time,
        };  
      }else {
        return state;
      }
    }
    case usq_actionTypes.UPDATE_UTILISATEUR_QUESTION_SUCCESSFUL: {
      //https://daveceddia.com/react-redux-immutability-guide/#redux-update-an-object-in-an-object
      const {
        id,
        id_reponse,
        reponse_ouverte,
        id_reponses_multiples,
      } = action.response;
      return {
        ...state,
        utilisateur_session_questions: state.utilisateur_session_questions.map(
          (item) => {
            if (item.id === id) {
              return {
                ...item,
                id_reponse,
                reponse_ouverte,
                id_reponses_multiples,
              };
            }
            return item;
          }
        ),
      };
    }
    default:
      return state;
  }
};

const isFetching = (state = false, action) => {
  switch (action.type) {
    case actionTypes.FETCH_UTILISATEUR_TEST_REQUEST:
      return true;
    case actionTypes.FETCH_UTILISATEUR_TEST_SUCCESSFUL:
    case actionTypes.FETCH_UTILISATEUR_TEST_FAILURE:
      return false;
    default:
      return state;
  }
};
const isUpdating = (state = false, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_UTILISATEUR_TEST_REQUEST:
      return true;
    case actionTypes.UPDATE_UTILISATEUR_TEST_SUCCESSFUL:
    case actionTypes.UPDATE_UTILISATEUR_TEST_FAILURE:
      return false;
    default:
      return state;
  }
};
const error = (state = null, action) => {
  switch (action.type) {
    case actionTypes.FETCH_UTILISATEUR_TEST_SUCCESSFUL:
    case actionTypes.UPDATE_UTILISATEUR_TEST_SUCCESSFUL:
    case actionTypes.FETCH_UTILISATEUR_TEST_REQUEST:
    case actionTypes.UPDATE_UTILISATEUR_TEST_REQUEST:
      return null;
    case actionTypes.FETCH_UTILISATEUR_TEST_FAILURE:
    case actionTypes.UPDATE_UTILISATEUR_TEST_FAILURE:
      return action.error;
    default:
      return state;
  }
};

const utilisateurTestStatus = combineReducers({
  isFetching,
  isUpdating,
  error,
});
export default utilisateurTestStatus;
export const getError = (state) => state.error;
export const getIsFetching = (state) => state.isFetching;
export const getIsUpdating = (state) => state.isUpdating;
