import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";
import throttle from "lodash/throttle";
import * as api from "../api/Api";
import { loadState, saveState, clearState } from "../localStorage";

const persistedState = loadState();
if (persistedState) {
  if (persistedState.auth) {
    if (persistedState.auth.api_token) {
      api.setConfigWithBearerTokenToken(persistedState.auth.api_token);
    }
  }
}
const middlewares = [thunk.withExtraArgument(api)];
const store = createStore(
  rootReducer,
  persistedState,
  applyMiddleware(...middlewares)
);

store.subscribe(
  throttle(() => {
    if (store.getState().auth && store.getState().auth.clear_local_storage) {
      clearState();
      return;
    }
    saveState({
      state: {
        candidat: store.getState().candidat,
        auth: store.getState().auth,
        lang: {
          current_lang: store.getState().lang["current_lang"],
          candidate_pref_lang: store.getState().lang["candidate_pref_lang"],
          candidate_profile_lang: store.getState().lang["candidate_profile_lang"],
        },
        utilisateurTest: {
          id: store.getState().utilisateurTest["id"],
          temps_ecoule: store.getState().utilisateurTest["temps_ecoule"],
        },
      },
    });
  }, 1000)
);

// if(process.env.NODE_ENV === 'development'){
//   store.subscribe(() => {
//     console.log(store.getState())
//   })
// }

export default store;
