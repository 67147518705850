import React from 'react';
import DropZone from './DropZone';

class DropZones extends React.Component {
    getDropZonesItems(value){
        return this.props.items.filter(item => item.id_reponse === value)  
    }

    render() {
      return(
        <div id="drop-zones-wrapper">
          { this.props.paniers.map(
            (zone, index) => (
              <DropZone
                key={'drop-zone-'+index}
                zone={zone}
                value={zone.id}
                removeCard={this.props.removeCard}
                items={this.getDropZonesItems(zone.id)}
                max={zone.nb_items}
                drop={this.props.drop} />
            )
          ) }
        </div>
      )
    }
  }

  export default DropZones