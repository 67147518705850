import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

function StartButton(props) {
  const { testId } = props;
  const link = "/test/" + testId + "/questionnaire";

  return (
    <div className="text-center">
      <Link to={link} className="btn btn-primary btn-lg">
        <FormattedMessage id="user_test.label.start-test" default="Débuter" />
      </Link>
    </div>
  );
}

export default StartButton;
