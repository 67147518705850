import React, { Component } from "react";
import * as candidatActions from "../../redux/actions/candidat/index";
import * as utilisateurSessionActions from "../../redux/actions/utilisateurSession/index";
import * as fromCandidatReducer from "../../redux/reducers/candidat";
import * as fromUtilisateurSessionReducer from "../../redux/reducers/utilisateurSession";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { bindActionCreators } from "redux";
import LoadingScreen from "../helpers/loadingScreen";
import FetchError from "../error/FetchError";
import LayoutProfessional from "./LayoutProfessional";
import LayoutAcademic10 from "./LayoutAcademic-10";
import LayoutCollege20 from "./LayoutCollege-20";
import { getConsentHTML, getValidDate } from "./helpers/helpers";

class Consent extends Component {
  constructor(props) {
    super(props);
    const { sexe } = this.props.candidat;
    this.state = {
      agreed: false,
      readNunderstood: false,
      sexe,
      year: undefined,
      month: undefined,
      day: undefined,
    };

    this.submit = this.submit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.fetchData();
  }

  fetchData() {
    const { fetchCandidat, fetchUtilisateurSession } = this.props.actions;

    fetchCandidat();
    fetchUtilisateurSession();
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  handleDateChange(year, month, day) {
    this.setState({
      year: year,
      month: month,
      day: day,
    });
  }

  submit = (e) => {
    e.preventDefault();
    const { updateCandidat, updateUtilisateurSession } = this.props.actions;
    const { candidat, utilisateurSession } = this.props;

    // This should not happen since submit button should be disabled if not agreed
    if (!this.state.agreed || !this.state.readNunderstood) {
      alert(this.props.intl.formatMessage({ id: "consent.not-agreed" }));
      return;
    }

    const dob = getValidDate(this.state.year, this.state.month, this.state.day);
    if (dob === false) {
      alert(this.props.intl.formatMessage({ id: "consent.invalid-dob" }));
      return;
    }

    // Update candidat and utilisateurSession
    updateCandidat(candidat.id, {
      sexe: this.state.sexe ? this.state.sexe : "O", // Defaults to "O" if not set
      date_naissance: !dob
        ? null
        : dob.toLocaleDateString("fr-CA", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          }),
    }).then(() => {
      updateUtilisateurSession(utilisateurSession.id, {
        consentement: getConsentHTML(
          this.state.year,
          this.state.month,
          this.state.day,
          this.state.sexe
        ),
      }).then(() => {
        this.props.history.push("/test");
      });
    });
  };

  render() {
    const {
      candidat,
      candidat_isFetching,
      candidat_isUpdating,
      candidat_error,
      utilisateurSession,
      utilisateurSession_isFetching,
      utilisateurSession_isUpdating,
      utilisateurSession_error,
    } = this.props;

    if (candidat_isFetching || utilisateurSession_isFetching) {
      return <LoadingScreen />;
    }

    if (candidat_error) {
      return <FetchError error={candidat_error} redirect={true} />;
    }

    if (utilisateurSession_error) {
      return <FetchError error={utilisateurSession_error} redirect={true} />;
    }

    if (
      utilisateurSession &&
      utilisateurSession.session &&
      utilisateurSession.session.type_audience_id === 10
    ) {
      return (
        <LayoutAcademic10
          candidat={candidat}
          utilisateurSession={utilisateurSession}
          handleInputChange={this.handleInputChange}
          handleDateChange={this.handleDateChange}
          sexe={this.state.sexe}
          submit={this.submit}
          agreed={this.state.agreed}
          readNunderstood={this.state.readNunderstood}
          pref_lang={this.props.lang.current_lang}
          cancel={() => this.props.history.push("/")}
        >
          {(candidat_isUpdating || utilisateurSession_isUpdating) && (
            <LoadingScreen />
          )}
        </LayoutAcademic10>
      );
    }

    if (
      utilisateurSession &&
      utilisateurSession.session &&
      utilisateurSession.session.type_audience_id === 20
    ) {
      return (
        <LayoutCollege20
          candidat={candidat}
          utilisateurSession={utilisateurSession}
          handleInputChange={this.handleInputChange}
          handleDateChange={this.handleDateChange}
          sexe={this.state.sexe}
          submit={this.submit}
          agreed={this.state.agreed}
          readNunderstood={this.state.readNunderstood}
          pref_lang={this.props.lang.current_lang}
          cancel={() => this.props.history.push("/")}
        >
          {(candidat_isUpdating || utilisateurSession_isUpdating) && (
            <LoadingScreen />
          )}
        </LayoutCollege20>
      );
    }

    if (
      utilisateurSession &&
      utilisateurSession.session &&
      utilisateurSession.session.type_audience_id === 1
    ) {
      return (
        <LayoutProfessional
          candidat={candidat}
          utilisateurSession={utilisateurSession}
          handleInputChange={this.handleInputChange}
          handleDateChange={this.handleDateChange}
          sexe={this.state.sexe}
          submit={this.submit}
          agreed={this.state.agreed}
          readNunderstood={this.state.readNunderstood}
          pref_lang={this.props.lang.current_lang}
          cancel={() => this.props.history.push("/")}
        >
          {(candidat_isUpdating || utilisateurSession_isUpdating) && (
            <LoadingScreen />
          )}
        </LayoutProfessional>
      );
    }

    return <LoadingScreen />;
  }
}

function mapStateToProps(state) {
  return {
    candidat: state.candidat,
    candidat_error: fromCandidatReducer.getError(state.candidatStatus),
    candidat_isFetching: fromCandidatReducer.getIsFetching(
      state.candidatStatus
    ),
    candidat_isUpdating: fromCandidatReducer.getIsUpdating(
      state.candidatStatus
    ),
    utilisateurSession: state.utilisateurSession,
    utilisateurSession_error: fromUtilisateurSessionReducer.getError(
      state.utilisateurSessionStatus
    ),
    utilisateurSession_isFetching: fromUtilisateurSessionReducer.getIsFetching(
      state.utilisateurSessionStatus
    ),
    utilisateurSession_isUpdating: fromUtilisateurSessionReducer.getIsUpdating(
      state.utilisateurSessionStatus
    ),
    lang: state.lang,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign({}, candidatActions, utilisateurSessionActions),
      dispatch
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(Consent));
