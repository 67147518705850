import React from 'react';
import SortingButton from './SortingButton';

class SortingButtons extends React.Component {
    render() {
      
      return(
        <div id="sorting-buttons">
            {this.props.options.map(
            (zone, index) => {
              return (
                <SortingButton
                  key={'sorting-button-'+index}
                  zone={zone}
                  value={zone.id}
                  onClickFunction={(e)=>{this.props.click(this.props.currentItem.id,{id_reponse: zone.id})}}
                  disabled={this.props.disabled} />
              )}
            )}
        </div>
      )
    }
  }

  export default SortingButtons