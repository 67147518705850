import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

const Example = (props) => {
  const { test } = props;
  const lang = useSelector((state) => state.lang.current_lang);
  const image_example = test && test[`image_exemple_${lang}`] ? test[`image_exemple_${lang}`] : undefined;

  if (!test || !image_example) {
    return <></>;
  }
  return (
    <div>
      <h3>
        <FormattedMessage id="user_test.title.example" />
      </h3>
      <div className="question-example-wrapper">
        <img
          src={"/images/questions/" + image_example}
          alt="Question example"
        />
      </div>
    </div>
  );
}

export default Example;
