import * as actions from "./actionTypes";


const fetchUtilisateurQuestionRequested = () => ({
    type: actions.FETCH_UTILISATEUR_QUESTION_REQUEST,
})
const fetchUtilisateurQuestionSuccessful = (response) => ({
    type: actions.FETCH_UTILISATEUR_QUESTION_SUCCESSFUL,
    response
})
const fetchUtilisateurQuestionFailure = (error) => ({
    type: actions.FETCH_UTILISATEUR_QUESTION_FAILURE,
    error
})
export const fetchUtilisateurQuestion = () => (dispatch, getState, api) => {
    dispatch(fetchUtilisateurQuestionRequested());
    return api.utilisateurQuestion.get().then(
        response => {
            dispatch(fetchUtilisateurQuestionSuccessful(response.data));
        },
        error => {
            dispatch(fetchUtilisateurQuestionFailure(error));
        }
    );
}

const updateUtilisateurQuestionRequested = () => ({
    type: actions.UPDATE_UTILISATEUR_QUESTION_REQUEST,
})
const updateUtilisateurQuestionSuccessful = (response) => ({
    type: actions.UPDATE_UTILISATEUR_QUESTION_SUCCESSFUL,
    response
})
const updateUtilisateurQuestionFailure = (error) => ({
    type: actions.UPDATE_UTILISATEUR_QUESTION_FAILURE,
    error
})
export const updateUtilisateurQuestion = (id, payload) => (dispatch, getState, api) => {
    dispatch(updateUtilisateurQuestionRequested());
    return api.utilisateurQuestion.put(id, payload).then(
        response => {
            dispatch(updateUtilisateurQuestionSuccessful(response.data));
        },
        error => {
            dispatch(updateUtilisateurQuestionFailure(error));
        }
    );
}

export const usePrevAnswersUtilisateurQuestion = (id, id_test, payload) => (dispatch, getState, api) => {
    dispatch(updateUtilisateurQuestionRequested());
    return api.utilisateurQuestion.usePrevAnswers(id, id_test, payload).then(
        response => {
            dispatch(updateUtilisateurQuestionSuccessful(response.data));
        },
        error => {
            dispatch(updateUtilisateurQuestionFailure(error));
        }
    );
}


export const dispatchLoadImgUtilisateurQuestionRequested = (id) =>(dispatch)=> {
    dispatch({type: actions.LOAD_IMG_UTILISATEUR_QUESTION_REQUEST,id})
}
export const dispatchLoadImgUtilisateurQuestionSuccessful = (id) =>(dispatch)=> {
    dispatch({type: actions.LOAD_IMG_UTILISATEUR_QUESTION_SUCCESSFUL,id})
}
export const dispatchLoadImgUtilisateurQuestionFailure = (id, error) =>(dispatch)=> {
    dispatch({type: actions.LOAD_IMG_UTILISATEUR_QUESTION_FAILURE,id, error})
}