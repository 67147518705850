import * as actionTypes from "../actions/utilisateurQuestion/actionTypes";
import { LOGOUT_SUCCESSFUL } from "../actions/auth/actionTypes";
import { combineReducers } from "redux";


export const utilisateurQuestion = (state = [], action) => {
  switch (action.type) {
    case actionTypes.FETCH_UTILISATEUR_QUESTION_SUCCESSFUL: {
      return {
        ...state,
      };
    }
    case LOGOUT_SUCCESSFUL: {
      return [];
    }
    default:
      return state;
  }
}

const isFetching = (state = false, action) => {
  switch (action.type) {
    case actionTypes.FETCH_UTILISATEUR_QUESTION_REQUEST:
      return true;
    case actionTypes.FETCH_UTILISATEUR_QUESTION_SUCCESSFUL:
    case actionTypes.FETCH_UTILISATEUR_QUESTION_FAILURE:
      return false;
    default:
      return state;
  }
};
const isUpdating = (state = false, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_UTILISATEUR_QUESTION_REQUEST:
      return true;
    case actionTypes.UPDATE_UTILISATEUR_QUESTION_SUCCESSFUL:
    case actionTypes.UPDATE_UTILISATEUR_QUESTION_FAILURE:
      return false;
    default:
      return state;
  }
};
const isImgLoading = (state = false, action) => {
  switch (action.type) {
    case actionTypes.LOAD_IMG_UTILISATEUR_QUESTION_REQUEST:
      return true;
    case actionTypes.LOAD_IMG_UTILISATEUR_QUESTION_SUCCESSFUL:
    case actionTypes.LOAD_IMG_UTILISATEUR_QUESTION_FAILURE:
      return false;
    default:
      return state;
  }
}


const error = (state=null, action) => {
  switch(action.type){
    case actionTypes.FETCH_UTILISATEUR_QUESTION_SUCCESSFUL:
    case actionTypes.UPDATE_UTILISATEUR_QUESTION_SUCCESSFUL:
    case actionTypes.FETCH_UTILISATEUR_QUESTION_REQUEST:
    case actionTypes.UPDATE_UTILISATEUR_QUESTION_REQUEST:
      return null;
    case actionTypes.FETCH_UTILISATEUR_QUESTION_FAILURE:
    case actionTypes.UPDATE_UTILISATEUR_QUESTION_FAILURE:
      return action.error
    default:
      return state;
    }
  }

const utilisateurQuestionStatus = combineReducers({isFetching, isUpdating, isImgLoading, error});
export default utilisateurQuestionStatus;
export const getError = (state) => state.error;
export const getIsFetching = (state) => state.isFetching;
export const getIsUpdating = (state) => state.isUpdating;
export const getIsImgLoading = (state) => state.isImgLoading;