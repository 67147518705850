import React from "react";
import LayoutProfessional from "./LayoutProfessional";
import LayoutAcademic10 from "./LayoutAcademic10";
import LayoutCollege20 from "./LayoutCollege20";

function Completed(props) {
  const { testCompletedHandler, utilisateurSession } = props;

  if (utilisateurSession.session.type_audience_id === 10) {
    return <LayoutAcademic10 testCompletedHandler={testCompletedHandler} />;
  }
  if (utilisateurSession.session.type_audience_id === 20) {
    return <LayoutCollege20 testCompletedHandler={testCompletedHandler} />;
  }

  return <LayoutProfessional testCompletedHandler={testCompletedHandler} />;
}

export default Completed;
