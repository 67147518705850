import React, { Component } from "react";
import { injectIntl, FormattedMessage } from "react-intl";

class TimesUpModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: true,
    };
  }

  toggleModal = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  
  render() {
    if(this.state.isOpen === false){
      return <></>
    }
    const audience = this.props.audienceId === 10 ? "academic10" : "professional";

    return (
      <div className="modal">
        <div className="modal-lg modal-wrapper">
          <div className="modal-head">
            <h2>
              <FormattedMessage id={`timer.times-up.${audience}.title`} />
            </h2>
          </div>
          <div className="modal-body">
            <p>
              <FormattedMessage id={`timer.times-up.${audience}.p1`} values={{ContinueLabel: <strong>{this.props.intl.formatMessage({id: `timer.times-up.${audience}.button.continue`})}</strong>}} />
            </p>

            {audience === "academic10" && (
              <p>
                <FormattedMessage id={"timer.times-up.academic10.p2"} values={{ContinueLabel: <strong>{this.props.intl.formatMessage({id: `timer.times-up.${audience}.button.continue`})}</strong>}} />
              </p>
            )}
          </div>
          <div className="modal-footer">
            <button onClick={this.props.onClose} className="btn btn-primary">
              <FormattedMessage
                id={`timer.times-up.${audience}.button.continue`}
              />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(TimesUpModal);
