import { FormattedMessage } from "react-intl";
import { useTranslation } from "../../../hooks/useTranslation";

function Row(props) {
  const { attachment } = props;
  const path = useTranslation("path", attachment);
  if (!path) return <></>;

  return (
    <a href={process.env.PUBLIC_URL + `/files/${path}`} target="_blank">
      {path}
    </a>
  );
}

function Attachments(props) {
  const { attachments } = props;
  return (
    <div id="attachments" className="table-wrapper">
      <h4>
        <FormattedMessage id="user-test.attachments.title" />
      </h4>
      <table className="table-listing">
        <thead>
          <tr>
            <th>
              <FormattedMessage id="user-test.attachments.file" />
            </th>
          </tr>
        </thead>
        <tbody>
          {attachments.map((attachment, index) => (
            <tr key={index}>
              <td>
                <Row attachment={attachment} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Attachments;
