import * as actionTypes from "../actions/utilisateurSession/actionTypes";
import { LOGOUT_REQUEST } from "../actions/auth/actionTypes";
import { combineReducers } from "redux";

export const utilisateurSession = (state = null, action) => {
  switch (action.type) {
    case actionTypes.FETCH_UTILISATEUR_SESSION_SUCCESSFUL:
    case actionTypes.UPDATE_UTILISATEUR_SESSION_SUCCESSFUL: {
      return {
        ...action.response,
      };
    }
    case LOGOUT_REQUEST: {
      return null;
    }
    default:
      return state;
  }
};

const isFetching = (state = false, action) => {
  switch (action.type) {
    case actionTypes.FETCH_UTILISATEUR_SESSION_REQUEST:
      return true;
    case actionTypes.FETCH_UTILISATEUR_SESSION_SUCCESSFUL:
    case actionTypes.FETCH_UTILISATEUR_SESSION_FAILURE:
      return false;
    default:
      return state;
  }
};
const isUpdating = (state = false, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_UTILISATEUR_SESSION_REQUEST:
      return true;
    case actionTypes.UPDATE_UTILISATEUR_SESSION_SUCCESSFUL:
    case actionTypes.UPDATE_UTILISATEUR_SESSION_FAILURE:
      return false;
    default:
      return state;
  }
};
const error = (state = null, action) => {
  switch (action.type) {
    case actionTypes.FETCH_UTILISATEUR_SESSION_SUCCESSFUL:
    case actionTypes.UPDATE_UTILISATEUR_SESSION_SUCCESSFUL:
    case actionTypes.FETCH_UTILISATEUR_SESSION_REQUEST:
    case actionTypes.UPDATE_UTILISATEUR_SESSION_REQUEST:
      return null;
    case actionTypes.FETCH_UTILISATEUR_SESSION_FAILURE:
    case actionTypes.UPDATE_UTILISATEUR_SESSION_FAILURE:
      return action.error;
    default:
      return state;
  }
};

const utilisateurSessionStatus = combineReducers({
  isFetching,
  isUpdating,
  error,
});
export default utilisateurSessionStatus;
export const getError = (state) => state.error;
export const getIsFetching = (state) => state.isFetching;
export const getIsUpdating = (state) => state.isUpdating;
