import * as actions from "./actionTypes";

const fetchUtilisateurTestRequested = () => ({
  type: actions.FETCH_UTILISATEUR_TEST_REQUEST
});
const fetchUtilisateurTestSuccessful = response => ({
  type: actions.FETCH_UTILISATEUR_TEST_SUCCESSFUL,
  response
});
const fetchUtilisateurTestFailure = error => ({
  type: actions.FETCH_UTILISATEUR_TEST_FAILURE,
  error
});
export const fetchUtilisateurTest = id => (dispatch, getState, api) => {
  dispatch(fetchUtilisateurTestRequested());
  return api.utilisateurTest.get(id).then(
    response => {
      dispatch(fetchUtilisateurTestSuccessful(response.data));
    },
    error => {
      dispatch(fetchUtilisateurTestFailure(error));
    }
  );
};
export const fetchTest = id => (dispatch, getState, api) => {
  return api.admin.test.get(id);
};

const updateUtilisateurTestElapsedTime = (id,time) => ({
  type: actions.UPDATE_UTILISATEUR_TEST_ELAPSED_TIME,
  id,
  time
});
const updateUtilisateurTestRequested = () => ({
  type: actions.UPDATE_UTILISATEUR_TEST_REQUEST
});
const updateUtilisateurTestSuccessful = response => ({
  type: actions.UPDATE_UTILISATEUR_TEST_SUCCESSFUL,
  response
});
const updateUtilisateurTestFailure = error => ({
  type: actions.UPDATE_UTILISATEUR_TEST_FAILURE,
  error
});
export const updateUtilisateurTest = (id, payload) => (
  dispatch,
  getState,
  api
) => {
  dispatch(updateUtilisateurTestRequested());
  return api.utilisateurTest.put(id, payload).then(
    response => {
      dispatch(updateUtilisateurTestSuccessful(response.data));
    },
    error => {
      dispatch(updateUtilisateurTestFailure(error));
    }
  );
};
export const usePrevTestAnswers = (id_user_session, id_test, payload) => (
  dispatch,
  getState,
  api
) => {
  dispatch(updateUtilisateurTestRequested());
  return api.utilisateurTest
    .usePrevAnswers(id_user_session, id_test, payload)
    .then(
      response => {
        dispatch(updateUtilisateurTestSuccessful(response.data));
      },
      error => {
        dispatch(updateUtilisateurTestFailure(error));
      }
    );
};
export const markUtilisateurTestAsCompleted = (id, payload) => (
  dispatch,
  getState,
  api
) => {
  dispatch(updateUtilisateurTestRequested());
  return api.utilisateurTest.completed(id, payload).then(
    response => {
      dispatch(updateUtilisateurTestSuccessful(response.data));
    },
    error => {
      dispatch(updateUtilisateurTestFailure(error));
    }
  );
};
export const updateUtilisateurTestElapsedTimeLocally = (
  id,
  time
) => dispatch => {
  dispatch(updateUtilisateurTestElapsedTime(id,time));
  return true;
};
