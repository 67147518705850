export const FETCH_UTILISATEUR_QUESTION_REQUEST     = "FETCH_UTILISATEUR_QUESTION_REQUEST";
export const FETCH_UTILISATEUR_QUESTION_SUCCESSFUL  = "FETCH_UTILISATEUR_QUESTION_SUCCESSFUL";
export const FETCH_UTILISATEUR_QUESTION_FAILURE     = "FETCH_UTILISATEUR_QUESTION_FAILURE";

export const UPDATE_UTILISATEUR_QUESTION_REQUEST     = "UPDATE_UTILISATEUR_QUESTION_REQUEST";
export const UPDATE_UTILISATEUR_QUESTION_SUCCESSFUL  = "UPDATE_UTILISATEUR_QUESTION_SUCCESSFUL";
export const UPDATE_UTILISATEUR_QUESTION_FAILURE     = "UPDATE_UTILISATEUR_QUESTION_FAILURE";

export const LOAD_IMG_UTILISATEUR_QUESTION_REQUEST     = "LOAD_IMG_UTILISATEUR_QUESTION_REQUEST";
export const LOAD_IMG_UTILISATEUR_QUESTION_SUCCESSFUL  = "LOAD_IMG_UTILISATEUR_QUESTION_SUCCESSFUL";
export const LOAD_IMG_UTILISATEUR_QUESTION_FAILURE     = "LOAD_IMG_UTILISATEUR_QUESTION_FAILURE";