import { useTranslation } from '../../../../hooks/useTranslation';

const OptionItem = (props) => {
  const { option } = props;

  const label = useTranslation("libelle", option);

  return (
    <span>
      {label}
    </span>
  );
}

export default OptionItem;