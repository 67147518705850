import React from 'react';
import { useTranslation } from '../../../../hooks/useTranslation';

function ReponseOuverte(props){
      const { current_question, onChange } = props;
      
      if(current_question.question.type_reponse.mode_reponse !== "OUV"){
        return null;
      }

      const consigne = useTranslation("consigne", current_question.question);
      
      return (
        <div id="reponse-ouverte">
          <label>{ consigne }</label>
          <textarea id="reponse-ouverte-input" onChange={onChange} defaultValue={current_question.reponse_ouverte} maxLength="2000"></textarea>
        </div>
      )
}

export default ReponseOuverte;