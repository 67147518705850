import React, { Component } from "react";
import TimesUpModal from "./TimesUpModal.js";
import { connect } from "react-redux";
import * as actions from "../../../../redux/actions/utilisateurTest/index";
import * as fromUtilisateurQuestionReducer from "../../../../redux/reducers/utilisateurQuestion";

const interval = 1 * 1000; // n seconds * 1000;
const intervalToDB = 5 * 1000; // n seconds * 1000;

class Timer extends Component {
  constructor(props) {
    super(props);

    const { utilisateurTest } = this.props;

    if (!window.localStorage) {
      this.props.onNoLocalStorage();
    }

    this.state = {
      elapsed: utilisateurTest.temps_ecoule ? utilisateurTest.temps_ecoule : 0,
      timesUp: false
    };

    this.updateProgressBar = this.updateProgressBar.bind(this);
    this.saveProgressToDB = this.saveProgressToDB.bind(this);
  }

  componentDidMount() {
    this.updateProgressBar(0);
    this.progressInterval = window.setInterval(() => {
      this.updateProgressBar(interval);
    }, interval);

    this.saveProgressToDBInterval = window.setInterval(() => {
      this.saveProgressToDB();
    }, intervalToDB);
  }

  updateProgressBar(interval) {
    const {
      utilisateurTest,
      updateUtilisateurTestElapsedTimeLocally,
    } = this.props;

    let elapsed = this.props.utilisateurQuestion_isUpdating || this.props.utilisateurQuestion_isImgLoading ? this.state.elapsed : this.state.elapsed + (interval / 1000);

    let countDown = this.formatRemainingTime(this.props.duration - elapsed);

    this.setState({
      elapsed,
      countDown,
    });

    updateUtilisateurTestElapsedTimeLocally(
      utilisateurTest.id,
      this.state.elapsed
    );

    if (elapsed < this.props.duration) {
      document.getElementById("timer-progress").style.width =
        Math.floor(100 - (elapsed / this.props.duration) * 100) + "%";
    } else {
      document.getElementById("timer-progress").style.width = 0;
      clearInterval(this.progressInterval);
      clearInterval(this.saveProgressToDBInterval);
      this.timesUp();
    }
  }

  saveProgressToDB() {
    const { utilisateurTest, updateUtilisateurTest } = this.props;
    updateUtilisateurTest(utilisateurTest.id, {
      temps_ecoule: this.state.elapsed,
    });
  }

  formatRemainingTime(remaining) {
    let f_remaining = "00:00";
    if (remaining > 0) {
      f_remaining = new Date(remaining * 1000).toISOString().substr(11, 8);
    }

    return f_remaining;
  }

  componentWillUnmount() {
    clearInterval(this.progressInterval);
    clearInterval(this.saveProgressToDBInterval);
    this.saveProgressToDB();
  }

  timesUp() {
    clearInterval(this.progressInterval);
    clearInterval(this.saveProgressToDBInterval);
    this.setState({ timesUp: true });
    this.props.onTimesUp();
  }

  render() {
    return (
      <div id="timer-wrapper">
        <div id="timer-progress" style={{ width: "100%" }} />
        <div id="count-down">{this.state.countDown}</div>
        {this.state.timesUp && (
          <TimesUpModal
            onClose={this.props.onTimesUpModalClosed}
            audienceId={this.props.audienceId}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    utilisateurTest: state.utilisateurTest,
    utilisateurQuestion_isUpdating: fromUtilisateurQuestionReducer.getIsUpdating(
      state.utilisateurQuestionStatus
    ),
    utilisateurQuestion_isImgLoading: fromUtilisateurQuestionReducer.getIsImgLoading(
      state.utilisateurQuestionStatus
    ),
  };
}

export default connect(mapStateToProps, actions)(Timer);
