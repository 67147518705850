import React from "react";
import { Route } from "react-router-dom";
import { Redirect } from "react-router";
import { connect } from "react-redux";

const TestingRoute = ({ component: Component, auth, props, ...rest }) => {
  let isTestEnv =
    process.env.NODE_ENV === "development" ||
    process.env.REACT_APP_CUSTOM_ENV === "dev" ||
    process.env.REACT_APP_CUSTOM_ENV === "staging" ||
    process.env.REACT_APP_CUSTOM_ENV === "php7" ||
    process.env.REACT_APP_CUSTOM_ENV === "php8"
      ? true
      : false;

  return isTestEnv && auth.isLoggedIn ? (
    <Route {...rest} render={(allProps) => <Component {...allProps} />} />
  ) : (
    <Redirect to="/connexion-requise" />
  );
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps)(TestingRoute);
