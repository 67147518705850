import React, { Component } from "react";
import { useIntl, FormattedMessage } from "react-intl";

class TestItems extends Component {
  render() {
    const Duration = (props) => {
      const intl = useIntl();
      let duree = undefined;
      if (props.isUserSessionMesuresAdaptees) {
        if (props.test.duree_max_adapt) {
          duree = props.test.duree_max_adapt;
        } else {
          duree = props.test.duree_max
            ? Number(props.test.duree_max)
            : Number(props.test.duree_approx);
        }
      } else {
        duree = props.test.duree_max
          ? Number(props.test.duree_max)
          : Number(props.test.duree_approx);
      }

      if (duree) {
        const min = Math.trunc(duree / 60);
        const sec = duree % 60;
        let duree_string = min ? min + " min." : "";
        duree_string += sec ? " " + sec + " sec." : "";

        if (props.test.duree_max) {
          return (
            <span>
              {duree_string} (
              <strong>
                {intl.formatMessage({ id: "home.questionnaires-chronometre" })}
              </strong>
              )
            </span>
          );
        } else if (props.test.duree_approx) {
          return <span>{duree_string}</span>;
        }
      }

      return <span className="text-muted">--</span>;
    };
    let testItems = [];
    if (!this.props.userTests) {
      return <span></span>;
    }
    testItems = this.props.userTests.map((userTest) => {
      const testName =
        this.props.lang === "es" && userTest.test.nom_es
          ? userTest.test.nom_es
          : this.props.lang === "en" && userTest.test.nom_en
          ? userTest.test.nom_en
          : userTest.test.nom;
      return (
        <tr key={userTest.id}>
          <td>{testName}</td>
          <td>
            <Duration
              test={userTest.test}
              isUserSessionMesuresAdaptees={Boolean(
                this.props.userSession.adapt
              )}
            />
          </td>
          <td>
            <FormattedMessage
              id="home.testItem.status"
              defaultMessage="--"
              values={{ completed: userTest.dt_termine !== null }}
            />
            <FormattedMessage
              id="home.testItem.in-person"
              defaultMessage=""
              values={{
                in_person:
                  userTest.dt_termine === null &&
                  this.props.userSession.currently_remote === true &&
                  userTest.test.remote === 0,
              }}
            />
          </td>
        </tr>
      );
    });
    return (
      <div className="table-wrapper">
        <table className="table-listing">
          <thead>
            <tr>
              <FormattedMessage id="app.global.label.name">
                {(txt) => <th>{txt}</th>}
              </FormattedMessage>
              <FormattedMessage id="app.global.label.approx_time">
                {(txt) => <th>{txt}</th>}
              </FormattedMessage>
              <FormattedMessage id="app.global.label.status">
                {(txt) => <th>{txt}</th>}
              </FormattedMessage>
            </tr>
          </thead>
          <tbody>
            {this.props.userTests.length > 0 ? (
              testItems
            ) : (
              <tr>
                <td>...</td>
                <td></td>
                <td></td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  }
}

export default TestItems;
