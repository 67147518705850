import React from "react";
import { useTranslation } from "../../../../hooks/useTranslation";

function Consignes(props) {
  const { question } = props;
  if (question.question.type_reponse.mode_reponse === "OUV") {
    return null;
  }

  const consigne = useTranslation("consigne", question.question) 

  return (
    <div id="consignes">
      <p>
        { consigne }
      </p>
    </div>
  );
}

export default Consignes;