import React, { Component } from "react";
import { FormattedMessage } from "react-intl";

class ProgressBar extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.currentIndex !== prevProps.currentIndex) {
      document.getElementById("progress").style.width =
        (
          (Math.min(this.props.currentIndex-1 >= 0 ? this.props.currentIndex-1 : 0, this.props.total) /
            this.props.total) *
          100
        ).toString() + "%";
    }
  }

  shouldComponentUpdate(nextProps) {
    if (this.props.currentIndex !== nextProps.currentIndex) {
      return true;
    }
    return false;
  }

  render() {
    return (
      <div>
        <div id="progress-bar">
          <div id="progress" style={{ width: "0" }}></div>
          <div id="count">
            {Math.min(this.props.currentIndex, this.props.total)}{" "}
            <FormattedMessage id="progress.of" defaultMessage="de" />{" "}
            {this.props.total}
          </div>
        </div>
      </div>
    );
  }
}

export default ProgressBar;
