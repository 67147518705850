import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import classNames from 'classnames';
import { helpers } from '../../../helpers/helpers';
import Audio from './Audio';
import * as utilisateurQuestionActions from "../../../../redux/actions/utilisateurQuestion/index";
import { useTranslation } from '../../../../hooks/useTranslation';

function Question(props){
  const { question, playAudio } = props;
  const [isMathJaxReady, setIsMathJaxReady] = useState(__MathJax_State__.isReady);
  const questionDomRef = useRef(null);
  // Some id_image are set to 0 (!)
  const imgSrc = question.question.id_image && question.question.id_image !== 0 ? `/images/questions/${question.question.id_image}.png` : "";
  const headerQuestionClassNames = classNames (
    'header-question',
    {
      'narrow': (question.question.id_image && question.question.id_image !== 0) || (question.question.info_fr && question.question.info_fr !== ""),
    }
  );

  // Refresh MathJax each time a question changes
  useEffect(()=>{
    // Avoid running this script if the MathJax library hasn't loaded yet.
    if (!isMathJaxReady) {
      // But trigger a re-render of this component once it is loaded.
      __MathJax_State__.promise.then(() => setIsMathJaxReady(true));
      return;
    }

  },[question, isMathJaxReady, setIsMathJaxReady]);

  useEffect(()=>{
    if(imgSrc !== ""){
      utilisateurQuestionActions.dispatchLoadImgUtilisateurQuestionRequested(question.id);
    }else {
      utilisateurQuestionActions.dispatchLoadImgUtilisateurQuestionSuccessful(question.id);
    }
  },[question.id])

  useLayoutEffect(()=>{
      MathJax.typeset();
  },[questionDomRef.current]);

  const text = useTranslation("texte", question.question);
  const info = useTranslation("info", question.question);

  return (<div ref={questionDomRef} id="question" key={question.id}>
            <h2 className={ headerQuestionClassNames }> {
              text &&
              helpers.replaceSapceBeforeColon(helpers.capitalize(text))
              }
            </h2>
            {
              info &&
                <div 
                  id="question-text"
                  className="question-text"
                  dangerouslySetInnerHTML={{__html:info}}
                />
            }
            { imgSrc !== "" &&
              <div className="question-image-wrapper">
                <img 
                  className="question-image"
                  src={imgSrc}
                  onLoad={()=>utilisateurQuestionActions.dispatchLoadImgUtilisateurQuestionSuccessful(question.id)}
                  onError={(error)=>utilisateurQuestionActions.dispatchLoadImgUtilisateurQuestionFailure(question.id, error)}
                  /> 
              </div>
            }
            <Audio current_question={question} playAudio={playAudio} />
          </div>);
}

export default Question;