import React from 'react';
import { FormattedMessage } from 'react-intl';

function Explication(props){

  const { question, onChange } = props;

  if(question.question.type_reponse.mode_reponse !== "REX"){
    return null;
  }

  return(
    <div id="reponse-explication">
      <label htmlFor=""><FormattedMessage id="questionnaire.label.reponse-explication"/></label>
      <textarea 
        id="reponse-explication-textarea"
        name="reponse-explication-textarea"
        onChange={ onChange }
        value={ (question.reponse_explication) ? question.reponse_explication : "" }
        maxLength="2000">
      </textarea>
      <p className="help-text text-right">Max. 2000</p>
    </div>
  );
}

export default Explication;