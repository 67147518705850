import * as actions from "./actionTypes";

const fetchCandidatRequested = () => ({
    type: actions.FETCH_CANDIDAT_REQUEST,
})
const fetchCandidatSuccessful = (response) => (dispatch) => {
    dispatch({
      type: actions.FETCH_CANDIDAT_SUCCESSFUL,
      response,
      asyncDispatch: dispatch,
    });
  };
const fetchCandidatFailure = (error) => ({
    type: actions.FETCH_CANDIDAT_FAILURE,
    error
})
export const fetchCandidat = (id) => (dispatch, getState, api) => {
    dispatch(fetchCandidatRequested());
    return api.candidat.get(id).then(
        response => {
            dispatch(fetchCandidatSuccessful(response.data));
        },
        error => {
            dispatch(fetchCandidatFailure(error));
        }
    );
}


const updateCandidatRequested = () => ({
    type: actions.UPDATE_CANDIDAT_REQUEST,
})
const updateCandidatSuccessful = (response) => ({
    type: actions.UPDATE_CANDIDAT_SUCCESSFUL,
    response
})
const updateCandidatFailure = (error) => ({
    type: actions.UPDATE_CANDIDAT_FAILURE,
    error
})
export const updateCandidat = (id, payload) => (dispatch, getState, api) => {
    dispatch(updateCandidatRequested());
    return api.candidat.put(id, payload).then(
        response => {
            dispatch(updateCandidatSuccessful(response.data));
        },
        error => {
            dispatch(updateCandidatFailure(error));
        }
    );
}