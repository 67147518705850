import React from "react";
import MiniCard from "./MiniCard";
import { useTranslation } from "../../../../../hooks/useTranslation";

const DropZone = (props) => {
  const label = useTranslation("libelle", props.zone);

  return (
    <div
      id={`drop-zone-${props.value}`}
      className="drop-zone"
      data-value={props.value}
      onDragOver={(e) => {
        e.preventDefault();
      }}
      onDrop={(e) => {
        props.drop(e.dataTransfer.getData("text"), {
          id_reponse: props.value,
        });
      }}
    >
      <div className="drop-zone-title">
        <h5 className="unselectable">{label}</h5>
        <div
          id={`counter-${props.value}`}
          className={
            "counter" + (props.items.length > props.max ? " full" : "")
          }
        >
          <span className="unselectable">{props.items.length}</span>
        </div>
      </div>
      <ul id={`drop-zone-${props.value}-list`}>
        {props.items.map((item) => (
          <MiniCard
            key={`mini-card-${item.id}`}
            id={`mini-card-${item.id}`}
            className="drop-zone"
            value={item.id}
            item={item}
            removeCard={() => {
              props.removeCard(item.id, { id_reponse: null });
            }}
          />
        ))}
      </ul>
    </div>
  );
};

export default DropZone;
