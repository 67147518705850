import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import TestItems from "../home/TestItems";
import { connect } from "react-redux";
import * as authActions from "../../redux/actions/auth/index";
import * as langActions from "../../redux/actions/lang/index";
import * as utilisateurSessionActions from "../../redux/actions/utilisateurSession/index";
import { bindActionCreators } from "redux";

class SessionConclusion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      utilisateurSession: undefined,
      candidat: undefined,
      lang: undefined,
    };
  }

  componentDidMount() {
    if (!this.props.auth || this.props.auth.isLoggedIn !== true) {
      this.props.history.push("/connexion-requise");
      return;
    }

    // Check if all tests in utilisateur_session are completed...
    try {
      let notCompletedUserTest = undefined;
      if (this.props.utilisateurSession.currently_remote) {
        notCompletedUserTest = this.props.utilisateurSession.userTests.find(
          (userTest) =>
            userTest.dt_termine === null && userTest.test.remote === 1
        );
      } else {
        notCompletedUserTest = this.props.utilisateurSession.userTests.find(
          (userTest) => userTest.dt_termine === null
        );
      }
      if (notCompletedUserTest) {
        this.props.history.push("/test");
        return;
      }
    } catch (e) {
      this.props.history.push("/error"); // Why are you here!?
      return;
    }

    // Map props to local state before logout (logout will clear redux store props)
    this.setState(
      {
        utilisateurSession: this.props.utilisateurSession,
        candidat: this.props.candidat,
        lang: this.props.lang
      },
      () => {
        this.props.actions.logout();
        this.props.actions.setCandidatePrefLang(this.state.lang.current_lang);
      }
    );
  }
  render() {
    const { utilisateurSession, candidat, lang } = this.state;
    
    if (!utilisateurSession || !candidat) {
      return <div>Loading...</div>;
    }

    let audience;

    switch (utilisateurSession.session.type_audience_id) {
      case 10:
        audience = "academic10";
        break;
      case 20:
        audience = "college";
        break;
      default:
        audience = "professional";
        break;
    }

    let testsInPersonRemaining = false;
    if (utilisateurSession.currently_remote === true) {
      testsInPersonRemaining = utilisateurSession.userTests.some(
        (userTest) => userTest.dt_termine === null && userTest.test.remote === 0
      );
    }
    return (
      <div className="page">
        <section>
          <h1>Conclusion</h1>
          <p>
            <FormattedMessage id={`sessionConclusion.${audience}.p1`} />
          </p>
          <p>
            <FormattedMessage
              id={`sessionConclusion.${audience}.p2`}
              values={{
                in_person: testsInPersonRemaining ? true : false,
              }}
            />
          </p>
          <p>
            <FormattedMessage id={`sessionConclusion.${audience}.p3`} />
          </p>

          <div>
            {utilisateurSession && (
              <TestItems
                userTests={utilisateurSession.userTests}
                userSession={utilisateurSession}
                lang={this.props.lang.current_lang}
              />
            )}
          </div>
        </section>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    candidat: state.candidat,
    auth: state.auth,
    lang: state.lang,
    utilisateurSession: state.utilisateurSession,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign({}, authActions, utilisateurSessionActions, langActions),
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SessionConclusion);
