import * as actions from "./actionTypes";


const fetchUtilisateurSessionRequested = () => ({
    type: actions.FETCH_UTILISATEUR_SESSION_REQUEST,
})
const fetchUtilisateurSessionSuccessful = (response) => ({
    type: actions.FETCH_UTILISATEUR_SESSION_SUCCESSFUL,
    response
})
const fetchUtilisateurSessionFailure = (error) => ({
    type: actions.FETCH_UTILISATEUR_SESSION_FAILURE,
    error
})
export const fetchUtilisateurSession = () => (dispatch, getState, api) => {
    dispatch(fetchUtilisateurSessionRequested());
    return api.utilisateurSession.get().then(
        response => {
            dispatch(fetchUtilisateurSessionSuccessful(response.data));
        },
        error => {
            dispatch(fetchUtilisateurSessionFailure(error));
        }
    );
}

const updateUtilisateurSessionRequested = () => ({
    type: actions.UPDATE_UTILISATEUR_SESSION_REQUEST,
})
const updateUtilisateurSessionSuccessful = (response) => ({
    type: actions.UPDATE_UTILISATEUR_SESSION_SUCCESSFUL,
    response
})
const updateUtilisateurSessionFailure = (error) => ({
    type: actions.UPDATE_UTILISATEUR_SESSION_FAILURE,
    error
})
export const updateUtilisateurSession = (id, payload) => (dispatch, getState, api) => {
    dispatch(updateUtilisateurSessionRequested());
    return api.utilisateurSession.put(id, payload).then(
        response => {
            dispatch(updateUtilisateurSessionSuccessful(response.data));
        },
        error => {
            dispatch(updateUtilisateurSessionFailure(error));
        }
    );
}