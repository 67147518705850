import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import * as utilisateurTestActions from "../../../redux/actions/utilisateurTest/index";
import * as utilisateurSessionActions from "../../../redux/actions/utilisateurSession/index";
import * as utilisateurQuestionActions from "../../../redux/actions/utilisateurQuestion/index";
import * as fromUtilisateurSessionReducer from "../../../redux/reducers/utilisateurSession";
import * as fromUtilisateurQuestionReducer from "../../../redux/reducers/utilisateurQuestion";
import * as fromUtilisateurTestReducer from "../../../redux/reducers/utilisateurTest";
import { bindActionCreators } from "redux";
import * as Api from "../../../api/Api";
import Timer from "./components/Timer";
import ProgressBar from "./components/ProgressBar";
import MultipleChoices from "./multiplechoices/MultipleChoices";
import CardSorting from "./cardSorsting/CardSorting";
import LoadingScreen from "../../helpers/loadingScreen";
import FetchError from "../../error/FetchError";
import BlockingScreen from "../../helpers/blockingScreen";

class Questionnaire extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      timesUp: false,
      intializing: true,
      currentIndex: undefined,
      currentQuestionOrder: undefined,
      progressTotal: undefined,
    };

    this.testCompletedHandler = this.testCompletedHandler.bind(this);
    this.timesUp = this.timesUp.bind(this);
    this.timesUpModalClosed = this.timesUpModalClosed.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.previousHandler = this.previousHandler.bind(this);
    this.saveHandler = this.saveHandler.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.fetchData();
  }

  fetchData() {
    const {
      fetchUtilisateurSession,
      fetchUtilisateurTest,
    } = this.props.actions;

    fetchUtilisateurSession();
    fetchUtilisateurTest(this.props.match.params.id).then(() => {
      this.setState({ intializing: false });
      if (
        this.props.utilisateurTest &&
        this.props.utilisateurTest.dt_commence === null
      ) {
        //Update dt_commence
        Api.utilisateurTest.put(this.props.utilisateurTest.id, {
          dt_commence: new Date(),
        });
      }
    });
  }

  previousHandler() {
    this.props.history.push("/test/");
  }

  updateProgress = (total, current) => {
    this.setState({
      currentIndex: current,
      currentQuestionOrder: current > -1 ? current + 1 : undefined,
      progressTotal: total,
    });
  };

  saveHandler = (idUtilisateurSessionQuestion, payload, callback) => {
    const { updateUtilisateurQuestion } = this.props.actions;

    updateUtilisateurQuestion(idUtilisateurSessionQuestion, payload).then(
      () => {
        callback();
      }
    );
  };

  timesUpModalClosed() {
    this.props.history.push("/test");
  }

  timesUp() {
    this.setState({ timesUp: true });
  }

  onNoLocalStorage() {
    this.props.history.push("/browser-outdated");
  }

  testCompletedHandler() {
    const { markUtilisateurTestAsCompleted } = this.props.actions;

    markUtilisateurTestAsCompleted(this.props.match.params.id, {
      timerIsOver: this.state.timesUp,
    }).then(() => {
      localStorage.removeItem("elapsed");
      if (this.state.timesUp === false) {
        this.props.history.push("/test");
      }
    });
  }

  render() {
    const {
      candidat,
      utilisateurTest,
      utilisateurSession,
      utilisateurQuestion_isFetching,
      utilisateurQuestion_isUpdating,
      utilisateurQuestion_isImgLoading,
      utilisateurQuestion_error,
      utilisateurSession_isFetching,
      utilisateurSession_isUpdating,
      utilisateurSession_error,
      utilisateurTest_isFetching,
      utilisateurTest_isUpdating,
      utilisateurTest_error,
      lang,
    } = this.props;

    const pref_lang = lang.current_lang;

    if (utilisateurSession_error) {
      return <FetchError error={utilisateurSession_error} redirect={true} />;
    }

    if (utilisateurQuestion_error) {
      return <FetchError error={utilisateurQuestion_error} redirect={true} />;
    }

    if (utilisateurTest_error) {
      return <FetchError error={utilisateurTest_error} redirect={true} />;
    }

    if (
      this.state.intializing ||
      utilisateurTest_isFetching ||
      utilisateurSession_isFetching
    ) {
      return <LoadingScreen />;
    }

    if (utilisateurTest.id && utilisateurTest.dt_termine) {
      return <Redirect to={"/test"} />;
    }

    if (utilisateurSession.id && !utilisateurSession.consentement) {
      return <Redirect to={"/consentement"} />;
    }
    return (
      <div id='test'>
        <div>
          {utilisateurTest.test.duree_max && (
            <Timer
              duration={utilisateurSession.adapt ? this.props.utilisateurTest.test.duree_max_adapt : this.props.utilisateurTest.test.duree_max}
              onTimesUp={this.timesUp}
              onTimesUpModalClosed={this.timesUpModalClosed}
              onNoLocalStorage={this.onNoLocalStorage}
              audienceId={utilisateurSession.session.type_audience_id}
            />
          )}

          <ProgressBar
            id='progress-bar'
            total={this.state.progressTotal}
            currentIndex={this.state.currentIndex}
            currentQuestionOrder={this.state.currentQuestionOrder}
            isLoading={this.state.isLoading}
            className='progress-bar'
          />
        </div>

        {utilisateurTest.test.type_test === "RE" && (
          <MultipleChoices
            questions={utilisateurTest.utilisateur_session_questions}
            test={utilisateurTest.test}
            utilisateurSession={this.props.utilisateurSession}
            updateProgress={this.updateProgress}
            saveHandler={this.saveHandler}
            previousHandler={this.previousHandler}
            testCompletedHandler={this.testCompletedHandler}
            isTimeUp={this.state.timesUp}
            lang={lang}
          />
        )}
        {utilisateurTest.test.type_test === "PA" && (
          <CardSorting
            questions={utilisateurTest.utilisateur_session_questions}
            utilisateurSession={this.props.utilisateurSession}
            paniers={utilisateurTest.paniers}
            updateProgress={this.updateProgress}
            previousHandler={this.previousHandler}
            saveHandler={this.saveHandler}
            testCompletedHandler={this.testCompletedHandler}
            lang={lang}
          />
        )}
        {(utilisateurQuestion_isFetching || utilisateurQuestion_isUpdating || utilisateurQuestion_isImgLoading) && <LoadingScreen />}
        {(utilisateurSession_isUpdating) && <BlockingScreen />}
      </div>
    );
  }
}

function mapPropsToState(state) {
  return {
    candidat: state.candidat,
    utilisateurSession: state.utilisateurSession,
    utilisateurTest: state.utilisateurTest,
    utilisateurSession_error: fromUtilisateurSessionReducer.getError(
      state.utilisateurSessionStatus
    ),
    utilisateurSession_isFetching: fromUtilisateurSessionReducer.getIsFetching(
      state.utilisateurSessionStatus
    ),
    utilisateurSession_isUpdating: fromUtilisateurSessionReducer.getIsUpdating(
      state.utilisateurSessionStatus
    ),
    utilisateurQuestion_error: fromUtilisateurQuestionReducer.getError(
      state.utilisateurQuestionStatus
    ),
    utilisateurQuestion_isFetching: fromUtilisateurQuestionReducer.getIsFetching(
      state.utilisateurQuestionStatus
    ),
    utilisateurQuestion_isUpdating: fromUtilisateurQuestionReducer.getIsUpdating(
      state.utilisateurQuestionStatus
    ),
    utilisateurQuestion_isImgLoading: fromUtilisateurQuestionReducer.getIsImgLoading(
      state.utilisateurQuestionStatus
    ),
    utilisateurTest_error: fromUtilisateurTestReducer.getError(
      state.utilisateurTestStatus
    ),
    utilisateurTest_isFetching: fromUtilisateurTestReducer.getIsFetching(
      state.utilisateurTestStatus
    ),
    utilisateurTest_isUpdating: fromUtilisateurTestReducer.getIsUpdating(
      state.utilisateurTestStatus
    ),
    lang: state.lang,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        utilisateurTestActions,
        utilisateurQuestionActions,
        utilisateurSessionActions
      ),
      dispatch
    ),
  };
}

export default connect(mapPropsToState, mapDispatchToProps)(Questionnaire);
