import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { utilisateurSession as utilisateurSessionApi } from "../../api/Api";
import * as utilisateurSessionActions from "../../redux/actions/utilisateurSession/index";
import * as utilisateurTestActions from "../../redux/actions/utilisateurTest/index";
import * as candidatActions from "../../redux/actions/candidat/index";
import * as fromCandidatReducer from "../../redux/reducers/candidat";
import * as fromUtilisateurSessionReducer from "../../redux/reducers/utilisateurSession";
import * as fromUtilisateurTestReducer from "../../redux/reducers/utilisateurTest";
import LoadingScreen from "../helpers/loadingScreen";
import PreviouslyCompletedTest from "./PreviouslyCompletedTest";
import FetchError from "../error/FetchError";
import Header from "./intro/Header";
import Instructions from "./intro/Instructions";
import Example from "./intro/Example";
import StartButton from "./intro/StartButton";

class Test extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userTest: undefined,
    };

    this.usePreviousAnswers = this.usePreviousAnswers.bind(this);
    this.refreshHandler = this.refreshHandler.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.fetchData();
  }

  fetchData() {
    const { fetchCandidat, fetchUtilisateurSession } = this.props.actions;

    fetchCandidat();
    fetchUtilisateurSession().then(() => {
      utilisateurSessionApi.getNextUserTest().then((resp) => {
        this.setState({
          userTest: resp.data.userTest,
        });
      });
    });
  }

  usePreviousAnswers() {
    const { utilisateurSession } = this.props;
    const { usePrevTestAnswers } = this.props.actions;

    const userTest = this.state.userTest;
    const previouslyCompleted = this.state.userTest.previouslyCompleted;

    usePrevTestAnswers(utilisateurSession.id, userTest.id_test, {
      previousTestId: previouslyCompleted.id_test,
      previousUserSessionId: previouslyCompleted.id_utilisateur_session,
    }).then(() => {
      this.setState(
        {
          userTest: undefined,
        },
        () => {
          this.fetchData();
        }
      );
    });
  }

  // Called when API automatically used previous answwers (e.g. Chrono test completed less than 30 days ago)
  refreshHandler() {
    this.setState(
      {
        userTest: undefined,
      },
      () => {
        this.fetchData();
      }
    );
  }

  render() {
    const {
      candidat,
      candidat_isFetching,
      candidat_error,
      utilisateurSession,
      utilisateurSession_isFetching,
      utilisateurSession_error,
    } = this.props;

    const { userTest } = this.state;

    if (candidat_error) {
      return <FetchError error={candidat_error} redirect={true} />;
    }

    if (utilisateurSession_error) {
      return <FetchError error={utilisateurSession_error} redirect={true} />;
    }

    if (candidat_isFetching || utilisateurSession_isFetching) {
      return <LoadingScreen />;
    }

    if (
      utilisateurSession &&
      utilisateurSession.id &&
      !utilisateurSession.consentement
    ) {
      // No consent (mandatory)
      return <Redirect to={"/consentement"} />;
    }

    // Test previously completed?
    if (userTest && userTest.previouslyCompleted) {
      return (
        <PreviouslyCompletedTest
          userTest={userTest}
          previousUserTest={userTest.previouslyCompleted}
          utilisateurSession={utilisateurSession}
          refreshHandler={this.refreshHandler}
          usePreviousHandler={this.usePreviousAnswers}
          answerAgainHandler={() => {
            // Set previouslyCompleted to null...
            this.setState((prevState) => ({
              userTest: {
                ...prevState.userTest,
                previouslyCompleted: null,
              },
            }));
          }}
        />
      );
    }

    if (userTest) {
      return (
        <div id='intro' className='page'>
          <section>
            <Header
              userTest={userTest}
              remote={utilisateurSession.currently_remote}
              userTests={utilisateurSession.userTests}
              isUserSessionMesuresAdaptees={Boolean(utilisateurSession.adapt)}
            />
            <Instructions
              test={userTest.test}
              isUserSessionMesuresAdaptees={Boolean(utilisateurSession.adapt)}
            />
            <Example test={userTest.test} />
            <StartButton testId={userTest.id_test} />
          </section>
        </div>
      );
    }

    if (userTest === null) {
      // All done!!!
      return <Redirect to="/conclusion" />;
    }

    return <LoadingScreen />;
  }
}

function mapStateToProps(state) {
  return {
    candidat: state.candidat,
    candidat_isFetching: fromCandidatReducer.getIsFetching(
      state.candidatStatus
    ),
    candidat_error: fromCandidatReducer.getError(state.candidatStatus),
    utilisateurSession: state.utilisateurSession,
    utilisateurSession_isFetching: fromUtilisateurSessionReducer.getIsFetching(
      state.utilisateurSessionStatus
    ),
    utilisateurSession_error: fromUtilisateurSessionReducer.getError(
      state.utilisateurSessionStatus
    ),
    utilisateurTest_isFetching: fromUtilisateurTestReducer.getIsFetching(
      state.utilisateurTestStatus
    ),
    utilisateurTest_error: fromUtilisateurTestReducer.getError(
      state.utilisateurTestStatus
    ),
  };
}

function mapDispatchToProps(dispatch) {
  // this.props.actions
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        candidatActions,
        utilisateurTestActions,
        utilisateurSessionActions
      ),
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Test);
