import React, { Component } from "react";
import * as actions from "../../../redux/actions/utilisateurTest/index";
import { connect } from "react-redux";
import Example from "./Example";
import Instructions from "./Instructions";
import Header from "./Header";


class TestIntro extends Component {
  constructor(props){
    super(props);


    this.state = { test: undefined, currentId: Number(this.props.match.params.id) };
    
    this.test_ids = [2, 3, 4, 5, 6, 8, 9, 10, 11, 12, 13, 14, 15, 16, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 31, 32, 33, 34, 35, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 52, 55, 58, 61, 63, 65, 67, 69, 71, 73, 78, 80, 86, 92, 95, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 112, 113, 114, 115, 116, 117];
    this.fetchData = this.fetchData.bind(this);
    this.handlePrev = this.handlePrev.bind(this);
    this.handleNext = this.handleNext.bind(this);
  }
  
  componentDidMount() {
    window.scrollTo(0, 0);
    if(this.state.currentId){
      this.fetchData(this.state.currentId);
    }else {
      this.fetchData(this.test_ids[0]);
    }
  }

  fetchData(id) {
    const { fetchTest } = this.props;

    fetchTest(id).then((resp)=>{
      this.setState({test: resp.data, currentId: Number(id)}, ()=>{
        this.props.history.push(id.toString());
      });
    })
  }

  handleNext() {
    const currentIndex = this.test_ids.indexOf(this.state.currentId);
    let nextIndex = 0;
    if(currentIndex >= 0 && currentIndex+1 < this.test_ids.length){
      nextIndex = currentIndex + 1;
    }
    this.fetchData(this.test_ids[nextIndex]);
  }

  handlePrev() {
    const currentIndex = this.test_ids.indexOf(this.state.currentId);
    let prev = currentIndex - 1;
    if(currentIndex === 0 ){
      prev = this.test_ids.length-1;
    }
    this.fetchData(this.test_ids[prev]);
  }

  render() {
    const { lang } = this.props;
    const { test } = this.state;
    const pref_lang = lang.current_lang;


    if( !test){
      return(<div></div>);
    }

    return (
      <div id='intro' className='page'>
        <div>
          <p>
            ID: {test.id} || Remote: {test.remote ? "Oui" : "Non"} || Durée approx: {test.duree_approx / 60} min.
          </p>
          <button onClick={this.handlePrev}>Précédent</button>
          <button onClick={this.handleNext}>Suivant</button>
        </div>
        <section>
          <Header remote={false} userTests={null} test={test} pref_lang={pref_lang} isUserSessionMesuresAdaptees={false} />
          <Instructions test={test} pref_lang={pref_lang} />
          <Example test={test} pref_lang={pref_lang} />
        </section>
      </div>
    );

  }
}

function mapStateToProps(state) {
  return {
    lang: state.lang
  };
}

export default connect(mapStateToProps, actions)(TestIntro);