import * as actionTypes from "../actions/lang/actionTypes";
import * as translationActionTypes from "../actions/translation/actionTypes";
import * as authActionTypes from "../actions/auth/actionTypes";
import * as candidateActionTypes from "../actions/candidat/actionTypes";
import * as utilisateurSessionActionTypes from "../actions/utilisateurSession/actionTypes";

const initialState = {
  default_app_lang: process.env.REACT_APP_DEFAULT_LANG, // Should always remain the same
  current_lang: process.env.REACT_APP_DEFAULT_LANG,
  forced_lang: null, // From db session.forcer_langue
  candidate_pref_lang: null, // From UI language dropdown
  candidate_profile_lang: null, // From db candidates.langue
};

export const lang = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_CANDIDATE_PREF_LANG_REQUEST:
      return {
        ...state,
        candidate_pref_lang: action.lang,
        current_lang: state.forced_lang ?? action.lang,
      };
    case candidateActionTypes.FETCH_CANDIDAT_SUCCESSFUL:
      return {
        ...state,
        candidate_profile_lang: action.response.langue,
        current_lang:
          state.forced_lang ??
          state.candidate_pref_lang ??
          action.response.langue ??
          state.current_lang,
      };
    case utilisateurSessionActionTypes.FETCH_UTILISATEUR_SESSION_SUCCESSFUL:
      return {
        ...state,
        forced_lang: action.response.session.forcer_langue,
        current_lang:
          action.response.session.forcer_langue ?? state.current_lang,
      };
    case translationActionTypes.SET_TRANSLATION_MISSING_REQUEST:
      return {
        ...state,
        current_lang: action.payload.lang_used ?? state.current_lang,
      };
    case translationActionTypes.RESET_TRANSLATION_MISSING_REQUEST:
      return {
        ...state,
        current_lang:
          state.forced_lang ??
          state.candidate_pref_lang ??
          state.candidate_profile_lang ??
          state.default_app_lang,
      };
    case authActionTypes.LOGOUT_REQUEST:
      return initialState;
    default:
      return state;
  }
};