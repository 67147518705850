// Consent helpers
// =================

/**
 * Returns consent HTML with dynamic elements replaced with static text
 * @param {string} year
 *  @param {string} month
 * @param {string} day
 * @param {string} sexe
 * @returns {string} consentHTML
 * */
export const getConsentHTML = (year, month, day, sexe) => {
  const source = document.getElementById("consent-content");
  var consentHTML = document.createElement("div");
  consentHTML.setAttribute("id", "consent-snapshot");
  consentHTML.appendChild(source.cloneNode(true));
  consentHTML
    .querySelector("#sexe")
    .insertAdjacentHTML("afterend", "<p>" + sexe + "</p>");
  consentHTML.querySelector("#sexe").remove();
  consentHTML
    .querySelector("#ddn_select")
    .insertAdjacentHTML(
      "afterend",
      "<p>" + year + "-" + month + "-" + day + "</p>"
    );
  consentHTML.querySelector("#ddn_select").remove();
  consentHTML
    .querySelector("#agreement_chkb-label")
    .insertAdjacentText("afterbegin", "[x] ");
  consentHTML.querySelector("#agreement_chkb").remove();
  consentHTML.querySelector("#ok-btn-wrapper").remove();
  consentHTML.querySelector("#cancel-btn-wrapper").remove;

  return consentHTML.innerHTML;
};

/**
 * Get valid date
 * Returns a Date object if valid, null if empty, false if invalid
 *
 * @param {string} year
 * @param {string} month
 * @param {string} day
 * @param {boolean} required
 * @returns Date object, null or false
 */
export const getValidDate = (year, month, day, required = false) => {
  if (required === false) {
    // Allow empty date as long as all fields are empty
    if (!year && !month && !day) {
      return null;
    }
  }

  const d = new Date(year, (Number(month) - 1).toString(), day);

  if (
    d.getFullYear() != year ||
    d.getMonth() != month - 1 ||
    d.getDate() != day
  ) {
    // User date is invalid (reverse date check)
    // (e.g. Feb 31st OR some fileds are empty, some are not)
    return false;
  }

  // User date is valid
  return d;
};
