import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as translationActions from "../redux/actions/translation/index";

export const useTranslation = (key, dict) => {
  const langStore = useSelector((state) => state.lang);
  const dispatch = useDispatch();
  const [missing, setMissing] = useState(false);
  const [translation, setTranslation] = useState();

  // Set translation on changes
  useEffect(() => {
    let current_lang = langStore.current_lang;
    if (dict[key + "_" + current_lang]) {
      setMissing(false);
      setTranslation(dict[key + "_" + current_lang]);
    } else {
      setMissing(true);
      setTranslation(null);
    }
  }, [langStore.current_lang, dict]);

  // Dispatch Redux action if translation is missing
  // -----------------------------------------------
  // The dispatched action will be catched by the "lang" reducer"
  // and set current_lang to the fallback lang. Dispatch only if
  // translation is missing AND fallback lang exists.
  useEffect(() => {
    if (missing === false) return;
    let fallbackLang = getFallbackLang(key, dict, langStore);
    if (!fallbackLang) return;
    dispatch(
      translationActions.setTranslationMissing({
        is_missing: true,
        lang_used: fallbackLang,
      })
    );
  }, [translation]);

  return translation;
};

// Get fallback language
const getFallbackLang = (key, dict, langStore) => {
  let fallbackLang = null;
  const fallbackPrecedences = [
    "forced_lang",
    "candidate_pref_lang",
    "candidate_profile_lang",
    "default_app_lang",
  ];

  for (let i = 0; i < fallbackPrecedences.length; i++) {
    let lang = langStore[fallbackPrecedences[i]];
    if (lang) {
      if (dict[key + "_" + lang]) {
        fallbackLang = lang;
        break;
      }
    }
  }

  return fallbackLang;
};
