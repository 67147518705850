import { combineReducers } from "redux";
import candidatStatus, { candidat } from "./candidat";
import {lang} from "./lang";
import {translation} from "./translation";
import authStatus, { auth } from "./auth";
import utilisateurSessionStatus, { utilisateurSession } from "./utilisateurSession";
import utilisateurTestStatus, { utilisateurTest } from "./utilisateurTest";
import utilisateurQuestionStatus, { utilisateurQuestion } from "./utilisateurQuestion";

export default combineReducers({
    candidat, candidatStatus,
    auth, authStatus,
    lang, translation,
    utilisateurSession, utilisateurSessionStatus,
    utilisateurTest, utilisateurTestStatus,
    utilisateurQuestion, utilisateurQuestionStatus,
});

