import { useTranslation } from "../../../hooks/useTranslation";
import { FormattedMessage } from "react-intl";
import Attachments from "./Attachments";

const Instructions = (props) => {
  const { test, isUserSessionMesuresAdaptees } = props;
  const instructions = useTranslation(
    isUserSessionMesuresAdaptees
      ? "message_intro_adapt"
      : "message_intro",
    test
  );

  return (
    <div>
      <h3>
        <FormattedMessage id="user-test.title.instructions" />
      </h3>
      <div
        id="intro-instructions"
        dangerouslySetInnerHTML={{
          __html: instructions,
        }}
      />

      {test.test_attachments?.length > 0 && (
        <Attachments attachments={test.test_attachments} />
      )}
    </div>
  );
}

export default Instructions;
