import React from "react";
import { useTranslation } from "../../../../../hooks/useTranslation";

const Consigne = (props) => {
  const consigne = useTranslation("consigne", props.question);
  return (
      <h4 className='unselectable'>{consigne}</h4>
  );
};

export default Consigne;