import React, { Component } from 'react';
import PremierTri from './dragAndDrop/PremierTri';
import TopBottomPicker from './topBottomPicker/TopBottomPicker';
import { FormattedMessage } from "react-intl";
import Completed from "../components/completed/Completed";

class CardSorting extends Component {
  constructor(props){
    super(props)

    this.state = {
      currentStep: 1,
      questionsPanier1erTri: this.props.questions.filter(q => {return q.question.type_reponse.mode_reponse === 'PA1'}),
      questionsPanier2eTri: this.props.questions.filter(q => {return q.question.type_reponse.mode_reponse === 'PA2'}),
    }

    this.steps = [{type: 'PA1'}].concat(this.state.questionsPanier2eTri) // Step 1 : 1er Tri, Step N : 2e Tri 
    this.totalQuestions = this.props.questions.filter(q => {return q.question.type_reponse.mode_reponse === 'PA1' || q.question.type_reponse.mode_reponse === 'OUV'}).length +
      this.props.questions.filter(q => {return q.question.type_reponse.mode_reponse === 'PA2'})
        .reduce(
            (accumulator, currentItem) =>
              accumulator + Number(currentItem.question.type_reponse.nb_reponse_obligatoire), 0
        )

    this.nextStepHandler = this.nextStepHandler.bind(this)
    this.previousStepHandler = this.previousStepHandler.bind(this)
    this.isNextStepEnabled = this.isNextStepEnabled.bind(this)
    this.updateProgress = this.updateProgress.bind(this)
  }
  
  componentDidMount(){
    this.updateProgress()
  }

  static getDerivedStateFromProps(props, state) {
    if(state.currentStep === 1){
      return {
        questionsPanier1erTri: props.questions.filter(q => {return q.question.type_reponse.mode_reponse === 'PA1'}),
      }
    }else if(state.currentStep > 1){
      return {
        questionsPanier2eTri: props.questions.filter(q => {return q.question.type_reponse.mode_reponse === 'PA2'}),
      };
    }

    // No state update necessary
    return null;
  }
  
  updateProgress(){
    const questionsPanier1erTri = this.props.questions.filter( q => q.question.type_reponse.mode_reponse === 'PA1')
    const questionsPanier2eTri = this.props.questions.filter( q => q.question.type_reponse.mode_reponse === 'PA2')
    const PA2reducer = function (accumulator, currentItem){
      let nbReponsesMultiples = (currentItem.id_reponses_multiples) ? currentItem.id_reponses_multiples.split(',').length : 0
      return  accumulator + nbReponsesMultiples
    }
    let nbQuestionsAnswered  = questionsPanier1erTri.filter(q => {return q.id_reponse !== null}).length
    nbQuestionsAnswered      += questionsPanier2eTri.reduce(PA2reducer,0)

    this.props.updateProgress(this.totalQuestions, nbQuestionsAnswered)
  }

  areDropZonesMaxBusted(paniers, questions){
    let areDropZonesMaxBusted = false
    for(let i = 0; i < paniers.length; i++){
      let nb = questions.filter(q=>q.id_reponse === paniers[i].id).length
      let max = paniers[i].nb_items
      if(nb > max){
        areDropZonesMaxBusted = true
        break
      }
    }

    return areDropZonesMaxBusted
  }

  isNextStepEnabled(){
    if (this.state.currentStep === 1){
      let areAllQuestionsAnswered = this.state.questionsPanier1erTri.findIndex((item)=>{return item.id_reponse === null}) === -1
      if(areAllQuestionsAnswered === true && this.areDropZonesMaxBusted(this.props.paniers,this.state.questionsPanier1erTri) === false){
        return true
      }else {
        return false
      }
    }else if(this.state.currentStep <= this.steps.length){ // mode_reponse === 'PA2'
      let nb_rep_obligatoires = Number(this.state.questionsPanier2eTri[this.state.currentStep-2].question.type_reponse.nb_reponse_obligatoire)
      let nb_reponses = (this.state.questionsPanier2eTri[this.state.currentStep-2].id_reponses_multiples) ?
          this.state.questionsPanier2eTri[this.state.currentStep-2].id_reponses_multiples.split(',').length : 0
      
      return nb_reponses >= nb_rep_obligatoires
    }else { // this.state.currentStep > this.steps.length
      return false;
    }
  }

  previousStepHandler(){
    if(this.state.currentStep === 1){
      this.props.previousHandler()
    }else {
      this.setState((state) => { return {currentStep: state.currentStep-1} })
    }
  }
  
  nextStepHandler(){
    if(this.state.currentStep > this.steps.length){
      this.props.testCompletedHandler()
    }else {
      this.setState((state) => { return {currentStep: state.currentStep+1} })
    }
  }

  render() {
    return (
      <div id="card-sorting">
        {this.state.currentStep === 1 && (
          <PremierTri
            paniers={this.props.paniers}
            items={this.state.questionsPanier1erTri}
            items2eTri={this.state.questionsPanier2eTri}
            updateProgress={this.updateProgress}
            saveHandler={this.props.saveHandler}
          />
        )}
        {this.state.currentStep <= this.steps.length &&
          this.state.currentStep > 1 && (
            <TopBottomPicker
              currentQuestion={
                this.state.questionsPanier2eTri[this.state.currentStep - 2]
              }
              updateProgress={this.updateProgress}
              saveHandler={this.props.saveHandler}
              items={this.state.questionsPanier1erTri.filter((item) => {
                return (
                  item.id_reponse ===
                  this.props.paniers.find(
                    (e) =>
                      e.id_question_2e_tri ===
                      this.state.questionsPanier2eTri[
                        this.state.currentStep - 2
                      ].id_question
                  ).id
                );
              })}
            />
          )}
        {this.state.currentStep > this.steps.length && (
          <Completed
            testCompletedHandler={this.props.testCompletedHandler}
            utilisateurSession={this.props.utilisateurSession}
          />
        )}

        <div id="form-footer">
          <button
            id="previous-btn"
            className="btn btn-default unselectable"
            onClick={this.previousStepHandler}
          >
            <FormattedMessage id="app.global.button.previous" />
          </button>
          <button
            id="next-button"
            className={
              "unselectable btn" +
              (this.state.currentItem ? "" : " btn-primary")
            }
            onClick={this.nextStepHandler}
            disabled={!this.isNextStepEnabled()}
          >
            <FormattedMessage id="app.global.button.next" />
          </button>
        </div>
      </div>
    );
  }
}

export default CardSorting