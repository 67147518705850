import React from 'react';
import { useSelector } from 'react-redux';


function Audio(props){
  const { playAudio, current_question } = props;
  const langStore = useSelector(state => state.lang);
  const audioSrc =  current_question.question['id_audio_'+langStore.current_lang];
  
  if(!audioSrc){
    return (null);
  }

  return (
    <div id="play-btn-wrapper">
     <button id="play-btn" onClick={playAudio} data-value={'/audio/'+audioSrc+'.mp3'}><i id="play-btn-icon" className="icon-volume" /></button>
    </div>
  )
}

export default Audio;