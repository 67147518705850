import React from "react";
import * as candidatActions from "../../redux/actions/candidat/index";
import * as langActions from "../../redux/actions/lang/index";
import * as utilisateurSessionActions from "../../redux/actions/utilisateurSession/index";
import * as fromCandidatReducer from "../../redux/reducers/candidat";
import * as fromUtilisateurSessionReducer from "../../redux/reducers/utilisateurSession";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { injectIntl } from "react-intl";
import LoadingScreen from "../helpers/loadingScreen";
import FetchError from "../error/FetchError";
import LayoutProfessional from "./LayoutProfessional";
import LayoutCollege20 from "./LayoutCollege-20";
import LayoutAcademic10 from "./LayoutAcademic-10";

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.startBtnClicked = this.startBtnClicked.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.fetchData();
  }

  fetchData() {
    const { fetchCandidat, fetchUtilisateurSession } = this.props.actions;

    fetchCandidat();
    fetchUtilisateurSession();
  }

  startBtnClicked() {
    if (document.getElementById("test-lang-select")) {
      const { setCandidatePrefLang } = this.props.actions;
      setCandidatePrefLang(document.getElementById("test-lang-select").value);
    }
  }

  render() {
    const { lang } = this.props;
    const { candidat, candidat_isFetching, candidat_error } = this.props;

    const {
      utilisateurSession,
      utilisateurSession_isFetching,
      utilisateurSession_error,
    } = this.props;

    if (candidat_isFetching || utilisateurSession_isFetching) {
      return (
        <div id="home" className="page">
          <LoadingScreen />
        </div>
      );
    }

    if (candidat_error) {
      return <FetchError error={candidat_error} redirect={true} />;
    }

    if (utilisateurSession_error) {
      return <FetchError error={utilisateurSession_error} redirect={true} />;
    }

    if (!utilisateurSession) {
      return (
        <div id="home" className="page">
          <LoadingScreen />
        </div>
      );
    }

    return (
      <>
        {utilisateurSession?.session.type_audience_id === 10 && (
          <LayoutAcademic10
            candidat={candidat}
            utilisateurSession={utilisateurSession}
            lang={lang}
            onStartBtnClicked={this.startBtnClicked}
          />
        )}
        {utilisateurSession?.session.type_audience_id === 20 && (
          <LayoutCollege20
            candidat={candidat}
            utilisateurSession={utilisateurSession}
            lang={lang}
            onStartBtnClicked={this.startBtnClicked}
          />
        )}
        {utilisateurSession &&
          utilisateurSession?.session.type_audience_id != 10 &&
          utilisateurSession?.session.type_audience_id != 20 && (
            <LayoutProfessional
              candidat={candidat}
              utilisateurSession={utilisateurSession}
              lang={lang}
              onStartBtnClicked={this.startBtnClicked}
            />
          )}
        <div className="text-center">
          <a href={this.props.intl.formatMessage({ id: "home.translate_url" })}>
            <img
              src={this.props.intl.formatMessage({
                id: "home.translate_img_src",
              })}
              alt={this.props.intl.formatMessage({
                id: "home.translate_img_alt",
              })}
              style={{
                width: this.props.intl.formatMessage({
                  id: "home.translate_img_width",
                }),
              }}
            />
          </a>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    lang: state.lang,
    candidat: state.candidat,
    candidat_error: fromCandidatReducer.getError(state.candidatStatus),
    candidat_isFetching: fromCandidatReducer.getIsFetching(
      state.candidatStatus
    ),
    utilisateurSession: state.utilisateurSession,
    utilisateurSession_error: fromUtilisateurSessionReducer.getError(
      state.utilisateurSessionStatus
    ),
    utilisateurSession_isFetching: fromUtilisateurSessionReducer.getIsFetching(
      state.utilisateurSessionStatus
    ),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        candidatActions,
        utilisateurSessionActions,
        langActions
      ),
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Home));
