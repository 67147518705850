import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import DatePickerSelect from "../helpers/date-picker-select";

class LayoutCollege20 extends Component {
  render() {
    const { candidat, utilisateurSession, handleInputChange, sexe, submit, agreed, readNunderstood, handleDateChange, cancel, pref_lang } = this.props;
    return (
      <div id='consent' className='page'>
        <section>
          <div id='consent-content'>
            <div>
              <FormattedMessage id='consent.college.title' defaultMessage="Autorisation à l'évaluation">
                {(txt) => <h1>{txt}</h1>}
              </FormattedMessage>
              <FormattedMessage id='consent.college.intro' defaultMessage=''>
                {(txt) => <p>{txt}</p>}
              </FormattedMessage>
              <form className='form-vertical'>
                <fieldset>
                  <FormattedMessage id='consent.college.title.form' defaultMessage=''>
                    {(txt) => <h3>{txt}</h3>}
                  </FormattedMessage>
                  <FormattedMessage id='app.global.label.name' defaultMessage=''>
                    {(txt) => (
                      <label>
                        {txt}
                        <FormattedMessage id='app.global.semi-colon' />
                      </label>
                    )}
                  </FormattedMessage>
                  <div className='input-row'>
                    <input type='text' value={candidat.prenom} disabled={true} />
                    <input type='text' value={candidat.nom} disabled={true} />
                  </div>
                  <FormattedMessage id='app.global.label.gender' defaultMessage=''>
                    {(txt) => (
                      <label>
                        {txt}
                        <FormattedMessage id='app.global.semi-colon' />
                      </label>
                    )}
                  </FormattedMessage>
                  <select name='sexe' id='sexe' onChange={handleInputChange} value={sexe}>
                    <option value=''>--</option>
                    <FormattedMessage id='app.global.label.female'>{(txt) => <option value='F'>{txt}</option>}</FormattedMessage>
                    <FormattedMessage id='app.global.label.male'>{(txt) => <option value='M'>{txt}</option>}</FormattedMessage>
                    <FormattedMessage id='app.global.label.other'>{(txt) => <option value='O'>{txt}</option>}</FormattedMessage>
                  </select>
                  <FormattedMessage id='app.global.label.dob' defaultMessage=''>
                    {(txt) => (
                      <label>
                        {txt}
                        <FormattedMessage id='app.global.semi-colon' />
                      </label>
                    )}
                  </FormattedMessage>
                  <DatePickerSelect id='ddn_select' date={candidat.date_naissance} onChange={handleDateChange} />
                </fieldset>
              </form>
              <FormattedMessage id='consent.college.during-passation-I' defaultMessage=''>
                {(txt) => <h5>{txt}</h5>}
              </FormattedMessage>
              <ol>
                <FormattedMessage id='consent.college.p1' defaultMessage=''>
                  {(txt) => <li>{txt}</li>}
                </FormattedMessage>
                <FormattedMessage id='consent.college.p2' defaultMessage='' values={{ demandeur: utilisateurSession.demandeur }}>
                  {(txt) => <li>{txt}</li>}
                </FormattedMessage>
                <FormattedMessage id='consent.college.p3' defaultMessage=''>
                  {(txt) => <li>{txt}</li>}
                </FormattedMessage>
                <FormattedMessage id='consent.college.p4' defaultMessage='' values={{ demandeur: utilisateurSession.demandeur }}>
                  {(txt) => <li>{txt}</li>}
                </FormattedMessage>
                <FormattedMessage
                  id='consent.college.p5'
                  defaultMessage=''
                  values={{
                    demandeur: utilisateurSession.demandeur,
                    partenaire: utilisateurSession.partenaire ? utilisateurSession.partenaire : "e-Potentiel",
                  }}>
                  {(txt) => <li>{txt}</li>}
                </FormattedMessage>
                <FormattedMessage id='consent.college.p6' defaultMessage=''>
                  {(txt) => <li>{txt}</li>}
                </FormattedMessage>
              </ol>
            </div>

            <ul className='no-bullet vertical'>
              <li className='text-center'>
                <label id='agreement_chkb-label' htmlFor='agreement_chkb' className='inline'>
                  <input id='agreement_chkb' name='agreed' value='true' type='checkbox' checked={agreed} onChange={handleInputChange} />
                  <FormattedMessage id='consent.college.label.I-accept' />
                </label>
              </li>
              <li className='text-center'>
                <label id='readNunderstood_chkb-label' htmlFor='readNunderstood_chkb' className='inline'>
                  <input id='readNunderstood_chkb' name='readNunderstood' value='true' type='checkbox' checked={readNunderstood} onChange={handleInputChange} />
                  <FormattedMessage
                    id="consent.college.label.I-read-understood"
                    values={{
                      link: <a href={process.env.PUBLIC_URL + `/files/terms_${pref_lang ?? "en"}.html`} target="_blank"><FormattedMessage id="consent.academic-10.user-agreements.title" /></a>
                    }}
                  />
                </label>
              </li>
              <li id='ok-btn-wrapper' className='text-center'>
                <button className='btn btn-primary' onClick={submit} disabled={!agreed || !readNunderstood}>
                  <FormattedMessage id='app.global.button.continue' />
                </button>
              </li>
              <li id='cancel-btn-wrapper' className='text-center'>
                <button className='btn btn-link' onClick={cancel}>
                  <FormattedMessage id='app.global.button.cancel' />
                </button>
              </li>
            </ul>
          </div>
        </section>
        {this.props.children}
      </div>
    );
  }
}
export default LayoutCollege20;
