import axios from "axios";

let configWithBearerToken = undefined;
const baseURL = process.env.REACT_APP_API_URL;
const timeout = 30000;
const api = axios.create({
  baseURL: baseURL,
  timeout: timeout,
  headers: { "Content-Type": "application/json" },
});

export const setConfigWithBearerTokenToken = (token) => {
  configWithBearerToken = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
};

export const unsetConfigWithBearerTokenToken = () => {
  configWithBearerToken = {
    headers: {
      "Content-Type": "application/json",
    },
  };
};

export const tokenLogin = (token) => {
  return api.post("/tokenLogin", { login_token: token }).then((response) => {
    setConfigWithBearerTokenToken(response.data.api_token);
    return response;
  });
};

export const codePermLogin = (codePerm) => {
  return api
    .post("/codePermLogin", { code_perm: codePerm })
    .then((response) => {
      setConfigWithBearerTokenToken(response.data.api_token);
      return response;
    });
};

export const logout = () => {
  return api.get("/logout").then((response) => {
    unsetConfigWithBearerTokenToken();
    return response;
  });
};

export const candidat = {
  get: (id) => {
    let path = id ? "/candidat/" + id : "/candidat";
    return api.get(path, configWithBearerToken).then((response) => {
      return response;
    });
  },
  put: (id, payload) => {
    return api
      .put("/candidat/" + id, payload, configWithBearerToken)
      .then((response) => {
        return response;
      });
  },
};

export const utilisateurSession = {
  get: (id = "") => {
    let path = id ? "/utilisateur-session/" + id : "/utilisateur-session";
    return api.get(path, configWithBearerToken).then((response) => {
      return response;
    });
  },
  put: (id, payload) => {
    return api
      .put("/utilisateur-session/" + id, payload, configWithBearerToken)
      .then((response) => {
        return response;
      });
  },
  getNextUserTest: () => {
    return api
      .get("/utilisateur-session/nextUserTest", configWithBearerToken)
      .then((response) => {
        return response;
      });
  },
  completed: (id) => {
    return api
      .get("/utilisateur-session/" + id + "/completed", configWithBearerToken)
      .then((response) => {
        return response;
      });
  },
};

export const utilisateurTest = {
  get: (id) => {
    return api
      .get("/utilisateur-session-test/" + id, configWithBearerToken)
      .then((response) => {
        return response;
      });
  },
  put: (id, payload) => {
    return api
      .put("/utilisateur-session-test/" + id, payload, configWithBearerToken)
      .then((response) => {
        return response;
      });
  },
  usePrevAnswers: (id_user_session, id_test, payload) => {
    return api
      .post(
        "/utilisateur-session/" +
          id_user_session +
          "/utilisateur-session-test/" +
          id_test +
          "/usePreviousAnswers",
        payload,
        configWithBearerToken
      )
      .then((response) => {
        return response;
      });
  },
  completed: (id, payload) => {
    return api
      .post(
        "/utilisateur-session-test/" + id + "/completed",
        payload,
        configWithBearerToken
      )
      .then((response) => {
        return response;
      });
  },
};

export const utilisateurQuestion = {
  put: (id, payload) => {
    return api
      .put(
        "/utilisateur-session-question/" + id,
        payload,
        configWithBearerToken
      )
      .then((response) => {
        return response;
      });
  },
};

export const admin = {
  test: {
    get: (id) => {
      return api
        .get("/admin/test/" + id, configWithBearerToken)
        .then((response) => {
          return response;
        });
    },
  },
};
