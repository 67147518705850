import React from "react";
import { FormattedMessage } from "react-intl";

function Footer(props) {
  const {
    onFirst,
    isFirstEnabled,
    onPrevious,
    isPrevEnabled,
    onNext,
    isNextEnabled,
    onLast,
    isLastEnabled,
  } = props;

  return (
    <div id="form-footer">
      {onFirst && (
        <button
          className="btn-default"
          onClick={onFirst}
          disabled={!isFirstEnabled()}
        >
          <i className="icon-to-start" />
        </button>
      )}

      <button
        className="btn-default"
        onClick={onPrevious}
        disabled={!isPrevEnabled()}
      >
        <FormattedMessage id="app.global.button.previous" />
      </button>

      <button
        className="btn-primary"
        onClick={onNext}
        disabled={!isNextEnabled()}
      >
        <FormattedMessage id="app.global.button.next" />
      </button>

      {onLast && (
        <button
          className="btn-default"
          onClick={onLast}
          disabled={!isLastEnabled()}
        >
          <i className="icon-to-end" />
        </button>
      )}
      <button
        className="btn btn-default right"
        onClick={props.toggleInstructions}
      >
        <FormattedMessage id="user-test.title.instructions" />
      </button>
    </div>
  );
}

export default Footer;
