import React, { Component } from "react";
import Consignes from "./Consignes";
import Question from "./Question";
import Footer from "./Footer";
import OptionItems from "./OptionItems";
import Explication from "./Explication";
import ReponseOuverte from "./ReponseOuverte";
import Completed from "../components/completed/Completed";
import LoadingScreen from "../../../helpers/loadingScreen";
import InstructionsPopOver from "../components/InstructionsPopOver";
import * as fromUtilisateurQuestionReducer from "../../../../redux/reducers/utilisateurQuestion";
import { connect } from "react-redux";

class MultipleChoices extends Component {
  constructor(props) {
    super(props);

    this.state = {
      questions: this.props.questions,
      showInstructions: false,
      saveCalledUponTimesUp: false,
    };

    this.audio = new Audio();
    this.updateProgress = this.updateProgress.bind(this);
    this.save = this.save.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onNextButtonHandler = this.onNextButtonHandler.bind(this);
    this.getCurrentQuestionIndex = this.getCurrentQuestionIndex.bind(this);
    this.onFirstButtonHandler = this.onFirstButtonHandler.bind(this);
    this.onPreviousButtonHandler = this.onPreviousButtonHandler.bind(this);
    this.onLastButtonHandler = this.onLastButtonHandler.bind(this);
    this.loadFirstUnansweredQuestion = this.loadFirstUnansweredQuestion.bind(
      this
    );
    this.loadPreviousQuestion = this.loadPreviousQuestion.bind(this);
    this.loadNextQuestion = this.loadNextQuestion.bind(this);
    this.isNextEnabled = this.isNextEnabled.bind(this);
    this.isLastEnabled = this.isLastEnabled.bind(this);
    this.isPreviousEnabled = this.isPreviousEnabled.bind(this);
    this.isFirstEnabled = this.isFirstEnabled.bind(this);
    this.handleOuverteChange = this.handleOuverteChange.bind(this);
    this.playAudio = this.playAudio.bind(this);
    this.stopAudio = this.stopAudio.bind(this);
    this.handleExplicationChange = this.handleExplicationChange.bind(this);
    this.toggleInstructions = this.toggleInstructions.bind(this);
  }

  componentDidMount() {
    this.loadFirstUnansweredQuestion();
  }

  componentDidUpdate() {
    if (
      this.props.isTimeUp === true &&
      this.state.saveCalledUponTimesUp === false
    ) {
      this.setState(
        {
          saveCalledUponTimesUp: true,
        },
        () => {
          this.save(this.props.testCompletedHandler);
        }
      );
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.props.isTimeUp !== nextProps.isTimeUp ||
      this.state !== nextState ||
      this.props !== nextProps
    ) {
      return true;
    }
    return false;
  }

  isQuestionAnswered(question) {
    const { id_reponse, reponse_explication, reponse_ouverte } = question;
    switch (question.question.type_reponse.mode_reponse) {
      case "REX":
        return (
          id_reponse !== null &&
          reponse_explication !== null &&
          reponse_explication.length > 0
        );
      case "OUV":
        return reponse_ouverte !== null && reponse_ouverte.length > 0;
      default:
        return id_reponse !== null;
    }
  }

  getFirstUnansweredQuestion() {
    if (this.props.initialIndex) {
      return this.state.questions[this.props.initialIndex];
    }
    let question = this.state.questions.find((item) => {
      return this.isQuestionAnswered(item) === false;
    });
    return question
      ? question
      : this.state.questions[this.state.questions.length - 1];
  }

  getCurrentQuestionIndex() {
    if (this.state.current_question === null) {
      return this.state.questions.length - 1;
    }

    let index = this.state.questions.findIndex((item) => {
      return item.id_question === this.state.current_question.id_question;
    });

    return index;
  }

  loadNextQuestion() {
    let next_question = this.state.questions[this.getCurrentQuestionIndex() + 1]
      ? this.state.questions[this.getCurrentQuestionIndex() + 1]
      : null;
    this.setState(
      (state, props) => {
        return { current_question: next_question };
      },
      () => {
        this.updateProgress();
      }
    );
  }

  loadPreviousQuestion() {
    let prev_question;
    if (this.state.current_question === null) {
      prev_question = this.state.questions[this.state.questions.length - 1];
    } else {
      let current_question_index = this.getCurrentQuestionIndex();
      prev_question = this.state.questions[current_question_index - 1];
    }

    this.setState({ current_question: prev_question }, () => {
      this.updateProgress();
    });
  }

  loadFirstUnansweredQuestion() {
    this.setState(
      { current_question: this.getFirstUnansweredQuestion() },
      () => {
        this.updateProgress();
      }
    );
  }

  updateProgress() {
    let current;
    if (this.state.current_question !== null) {
      current = this.state.questions.findIndex((item) => {
        return item.id_question === this.state.current_question.id_question;
      });
    } else {
      current = this.state.questions.length;
    }

    this.props.updateProgress(this.state.questions.length, current + 1);
  }

  stopAudio() {
    if (this.audio.duration > 0 && !this.audio.paused) {
      this.audio.pause();
    }
  }

  playAudio(e) {
    e.preventDefault();

    if (this.audio.duration > 0 && !this.audio.paused) {
      this.audio.pause();
    } else {
      this.audio = new Audio(
        document.getElementById("play-btn").getAttribute("data-value")
      );
      this.audio.play();
    }
  }

  getTotalAnswered() {
    return this.state.questions.filter((item) => {
      return item.id_reponse !== null;
    }).length;
  }

  onChange(e) {
    this.stopAudio();
    let item = this.state.current_question;
    item.id_reponse = Number(e.target.value);
    this.setState(
      {
        current_question: item,
      },
      () => {
        /**
         * Si question mode_repsone est REX (question choix de réponse avec champ texte),
         * ne pas appeler Save maintenant car on passerait à la question suivante (effet non-désiré)
         */
        if (
          this.state.current_question.question.type_reponse.mode_reponse ===
          "REX"
        ) {
          this.save(() => {});
        } else {
          this.save(this.loadNextQuestion);
        }
      }
    );
  }

  save(callback) {
    if (!this.state.current_question) {
      if (callback) {
        callback();
      }
      return;
    }

    const {
      id,
      id_reponse,
      reponse_ouverte,
      reponse_explication,
    } = this.state.current_question;

    let payload = {
      id_reponse: id_reponse,
      reponse_explication: reponse_explication,
      reponse_ouverte: reponse_ouverte,
    };

    if (!callback) {
      callback = () => {};
    }

    this.setState(
      {
        isLoading: true,
      },
      () => {
        this.props.saveHandler(id, payload, callback);
      }
    );
  }

  onFirstButtonHandler(e) {
    this.stopAudio();
    this.setState({ current_question: this.state.questions[0] }, () => {
      this.updateProgress();
    });
  }

  onPreviousButtonHandler(e) {
    this.stopAudio();

    if (this.state.current_question === null) {
      // Page "Questionnaire complété" affichée
      this.loadPreviousQuestion();
      return;
    }

    if (this.getCurrentQuestionIndex() === 0) {
      return;
    } else {
      this.save(this.loadPreviousQuestion);
    }
  }

  isPreviousEnabled() {
    return this.getCurrentQuestionIndex() > 0;
  }

  onNextButtonHandler(e) {
    this.stopAudio();
    this.save(this.loadNextQuestion);
  }

  isNextEnabled() {
    if (this.state.current_question) {
      return this.isQuestionAnswered(this.state.current_question);
    }
    return false;
  }

  onLastButtonHandler(e) {
    this.stopAudio();
    this.save(this.loadFirstUnansweredQuestion);
  }

  isLastEnabled() {
    if (
      this.state.current_question &&
      this.isQuestionAnswered(this.state.current_question) &&
      this.state.questions[this.getCurrentQuestionIndex()]
    ) {
      if (
        this.isQuestionAnswered(
          this.state.questions[this.getCurrentQuestionIndex()]
        )
      ) {
        return true;
      }
    }

    return false;
  }

  isFirstEnabled() {
    if (this.state.current_question) {
      if (this.getCurrentQuestionIndex() === 0) {
        return false;
      }
    }

    return true;
  }

  handleOuverteChange(e) {
    let current_question = this.state.current_question;
    current_question.reponse_ouverte = e.target.value;
    this.setState({ current_question: current_question }, this.forceUpdate);
  }

  handleExplicationChange(e) {
    let current_question = this.state.current_question;
    current_question.reponse_explication = e.target.value;
    this.setState({ current_question: current_question }, this.forceUpdate);
  }

  toggleInstructions(e) {
    this.setState({ showInstructions: !this.state.showInstructions });
  }

  render() {
    const { current_question } = this.state;
    if (typeof current_question === "undefined") {
      return <LoadingScreen />;
    }
    return (
      <div id='multiple-choices'>
        {current_question === null && <Completed testCompletedHandler={this.props.testCompletedHandler} utilisateurSession={this.props.utilisateurSession} />}
        {current_question !== null && (
          <div id='question-wrapper' style={{visibility: this.props.utilisateurQuestion_isImgLoading ? 'hidden' : 'visible'}}>
            <Consignes question={current_question} />
            <Question question={current_question} playAudio={this.playAudio} />
            <OptionItems onChange={this.onChange} current_question={current_question}>
              <Explication question={current_question} onChange={this.handleExplicationChange} />
            </OptionItems>
            <ReponseOuverte current_question={current_question} onChange={this.handleOuverteChange} />
          </div>
        )}
        <Footer
          onFirst={this.onFirstButtonHandler}
          isFirstEnabled={this.isFirstEnabled}
          onPrevious={this.onPreviousButtonHandler}
          isPrevEnabled={this.isPreviousEnabled}
          onNext={this.onNextButtonHandler}
          isNextEnabled={this.isNextEnabled}
          onLast={this.onLastButtonHandler}
          isLastEnabled={this.isLastEnabled}
          toggleInstructions={this.toggleInstructions}
        />
        {this.state.showInstructions === true && (
          <InstructionsPopOver
            test={this.props.test}
            toggleInstructions={this.toggleInstructions}
            lang={this.props.lang}
            isUserSessionMesuresAdaptees={Boolean(this.props.utilisateurSession.adapt)}
          />
        )}
      </div>
    );
  }
}

function mapPropsToState(state) {
  return {
    utilisateurQuestion_isImgLoading: fromUtilisateurQuestionReducer.getIsImgLoading(
      state.utilisateurQuestionStatus
    ),
  };
}

export default connect(mapPropsToState)(MultipleChoices);
