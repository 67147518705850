import React, { Component } from "react";
import { Link } from "react-router-dom";
import { useIntl, FormattedMessage } from "react-intl";
import { helpers } from "../helpers/helpers";
import TestItems from "./TestItems";

class LayoutAcademic10 extends Component {
  render() {
    const { candidat, utilisateurSession, lang, onStartBtnClicked } = this.props;
    const localized_eprofil =
      lang.current_lang === "en" ? "TalenTest" : "TalenTest";

    const SelectLang = () => {
      const intl = useIntl();
      return (
        <select
          id="test-lang-select"
          name="test_lang"
          className=""
          defaultValue={lang.current_lang}
        >
          <option value="fr">
            {helpers.capitalize(
              intl.formatMessage({ id: "app.global.french" })
            )}
          </option>
          <option value="en">
            {helpers.capitalize(
              intl.formatMessage({ id: "app.global.english" })
            )}
          </option>
        </select>
      );
    };

    return (
      <div id='home' className='page'>
        <section>
          <div>
            <h1>
              <FormattedMessage id='home.academic-10.title' />
            </h1>
            <p>
              <FormattedMessage id='home.academic-10.intro.p1' values={{ name: candidat.prenom }} />
            </p>
            <p>
              <FormattedMessage id='home.academic-10.intro.p2' />
            </p>
            <p>
              <FormattedMessage
                id='home.academic-10.intro.p3'
                values={{
                  Demandeur: <strong>{utilisateurSession.demandeur}</strong>,
                  PrefixDemandeurFr: helpers.isApostropheNeeded(String(utilisateurSession.demandeur)) ? "d'" : "de ",
                  Gender: candidat.sexe,
                }}
              />
            </p>
            <p>
              <FormattedMessage id='home.academic-10.intro.p4' values={{ Gender: candidat.sexe }} />
            </p>
            <p>
              <FormattedMessage id='home.academic-10.intro.p5' />
            </p>
            <p>
              <FormattedMessage id='home.academic-10.intro.p6' />
            </p>
            <p>
              <FormattedMessage
                id='home.academic-10.intro.p7'
                values={{
                  partenaire: localized_eprofil,
                  prefix_partenaire_fr: helpers.isApostropheNeeded(String("TalenTest")) ? "d'" : "de ",
                }}
              />
            </p>
          </div>

          <div>
            <h3>
              <FormattedMessage id='home.academic-10.title.questionnaires' />
            </h3>
            <p>
              <FormattedMessage id='home.academic-10.questionnaires-list' values={{ is_adapt: Boolean(utilisateurSession.adapt) }} />
            </p>
            {utilisateurSession && <TestItems userTests={utilisateurSession.userTests} userSession={utilisateurSession} lang={lang.current_lang} />}
          </div>
          {utilisateurSession && (utilisateurSession.length === 0 || (utilisateurSession["session"] && utilisateurSession["session"]["forcer_langue"] === null)) && (
            <div>
              <h3>
                <FormattedMessage id='home.title.before-we-start' />
              </h3>
              <form>
                <div className='full-width'>
                  <label htmlFor='test_lang'>
                    <FormattedMessage id='home.label.select-lang' />
                  </label>
                  <SelectLang />
                </div>
              </form>
            </div>
          )}
          <div className='text-center'>
            <Link className='btn btn-primary btn-lg' to='/consentement' onClick={onStartBtnClicked}>
              <FormattedMessage id='app.global.button.start' />
            </Link>
          </div>
        </section>
      </div>
    );
  }
}

export default LayoutAcademic10;
