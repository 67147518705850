import React from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

function Header(props) {
  const { userTest, userTests, remote, isUserSessionMesuresAdaptees } = props;
  const lang = useSelector((state) => state.lang);


  let index,
    nbTests = undefined;
  if (userTests) {
    // If usersession is remote, only include remote tests...
    if (remote) {
      let remoteUserTests = userTests.filter((item) => item.test.remote === 1);
      index = remoteUserTests
        .map(function (userTest) {
          return userTest.id;
        })
        .indexOf(userTest.id);
      nbTests = remoteUserTests.length;
    } else {
      index = userTests
        .map(function (userTest) {
          return userTest.id;
        })
        .indexOf(userTest.id);
      nbTests = userTests.length;
    }
  }

  const DurationPill = () => {
    let duree = undefined;
    if(isUserSessionMesuresAdaptees){
      if(userTest.test.duree_max_adapt){
        duree = userTest.test.duree_max_adapt;
      }else {
        duree = userTest.test.duree_max
          ? Number(userTest.test.duree_max)
          : Number(userTest.test.duree_approx);
      }
    }else {
      duree = userTest.test.duree_max
        ? Number(userTest.test.duree_max)
        : Number(userTest.test.duree_approx);
    }

    if (duree) {
      const min = Math.trunc(duree / 60);
      const sec = duree % 60;
      let duree_string = min ? min + " min." : "";
      duree_string += sec ? " " + sec + " sec." : "";
      if (userTest.test.duree_max) {
        return (
          <div id="intro-timed-pill" className="intro-pill intro-timed-pill">
            {duree_string}
          </div>
        );
      } else {
        return (
          <div id="intro-approx-pill" className="intro-pill intro-approx-pill">
            {duree_string}
          </div>
        );
      }
    } else {
      return <span></span>;
    }
  };

  const TestTitle = () => {
    // Since userTest.test.nom_fr does not exist,
    // we can not use the hook "useTranslation".
    // We need to do it here "manually".
    if (lang.forced_lang) {
      if(lang.forced_lang === "fr"){
        return userTest.test["nom"];
      }
      return userTest.test["nom_" + lang.forced_lang];
    }

    if(lang.candidate_pref_lang){
      if(lang.candidate_pref_lang === "fr"){
        return userTest.test["nom"];
      }
      if(userTest.test["nom_" + lang.candidate_pref_lang]){
        return userTest.test["nom_" + lang.candidate_pref_lang];
      }
    }

    if(lang.candidate_profile_lang){
      if(lang.candidate_profile_lang === "fr"){
        return userTest.test["nom"];
      }
      if(userTest.test["nom_" + lang.candidate_profile_lang]){
        return userTest.test["nom_" + lang.candidate_profile_lang];
      }
    }
    
    if(lang.default_app_lang === "fr"){
      return userTest.test["nom"];
    }

    return userTest.test["nom_" + lang.default_app_lang];
  };

  return (
    <div id="instructions-header">
      {userTests && index >= 0 && nbTests && nbTests >= 1 && (
        <div id="intro-session-progress">
          Test {index + 1} <FormattedMessage id="progress.of" /> {nbTests}
        </div>
      )}
      <h1 id="intro-test-title">
        <TestTitle />
        {/* {test.duree_max && (
          <p id="intro-subtitle">
            <FormattedMessage id="user_test.questionnaires-chronometre" />
          </p>
        )} */}
      </h1>
      <DurationPill />
    </div>
  );
}

export default Header;
