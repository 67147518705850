import React from 'react';
import { useTranslation } from '../../../../../hooks/useTranslation';

const  MediumCard = (props) => {    
  var classNames = require('classnames');
      var mediumCardClass = classNames({
          'medium-card': true,
          'no-select': true,
          selected: props.selected,
          disabled: props.disabled
        });
      const label = useTranslation("texte", props.question);
      return(
        <button
          id={'medium-card-'+props.id_question}
          onClick={() => {props.onClick(props.id_question)}}
          data-value={props.id_question}
          className={mediumCardClass} disabled={props.disabled}>
            {label}
            <div className="check-box-wrapper">
              <input
                type="checkbox"
                value={props.id_question}
                onChange={()=>{}}
                checked={props.checked}
                disabled={props.disabled}/>
            </div>
        </button>
      )
  }

export default MediumCard