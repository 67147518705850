import * as actionCreators from "./actionCreators";

export const doTokenLogin = (token) => (dispatch, getState, api) => {
  dispatch(actionCreators.loginRequested(token));
  return api.tokenLogin(token).then(
    (response) => {
      dispatch(actionCreators.loginSuccessful(response.data));
    },
    (error) => {
      dispatch(actionCreators.loginFailure(token, error));
    }
  );
};

export const doCodePermLogin = (codePerm) => (dispatch, getState, api) => {
  dispatch(actionCreators.loginRequested(codePerm));
  return api.codePermLogin(codePerm).then(
    (response) => {
      dispatch(actionCreators.loginSuccessful(response.data));
    },
    (error) => {
      dispatch(actionCreators.loginFailure(codePerm, error));
    }
  );
};

export const logout = () => (dispatch, getState, api) => {
  dispatch(actionCreators.logoutRequested());
  return api.logout().then(
    (response) => {
      dispatch(actionCreators.logoutSuccessful(response));
    },
    (error) => {
      dispatch(actionCreators.logoutFailure(error));
    }
  );
};
