import { useSelector } from "react-redux";

const LangSelectorDropdown = (props) => {
  const { setCandidatePrefLang } = props;
  const utilisateurSession = useSelector((state) => state.utilisateurSession);
  return (
    <div
      id="lang-selector"
      style={{
        position: "absolute",
        backgroundColor: "#30373C",
        color: "#ABABAB",
        top: "53px",
        right: "10px",
        width: "125px",
        borderRadius: "8px",
        zIndex: "2000",
      }}
    >
      <ul>
        <li
          style={{ display: "block", padding: "10px" }}
          className="clickable"
          onClick={() => setCandidatePrefLang("fr")}
          translate="no"
        >
          Français
        </li>
        <li
          style={{ display: "block", padding: "10px" }}
          className="clickable"
          onClick={() => setCandidatePrefLang("en")}
          translate="no"
        >
          English
        </li>
        {(!utilisateurSession ||
          utilisateurSession.session.type_audience_id === 1) && (
          <li
            style={{ display: "block", padding: "10px" }}
            className="clickable"
            onClick={() => setCandidatePrefLang("es")}
            translate="no"
          >
            Español
          </li>
        )}
      </ul>
    </div>
  );
};

export default LangSelectorDropdown;
